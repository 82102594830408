// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { useMemo, useState } from 'react';
import { ItemGroupType, SummarizationSettingsInput, SummarizationStyle } from '../../../../../__generated__/graphql';
import { FontFamily } from '../../../../../theme';
import { getActivityFilters } from '../filters/activity-property-helpers/from-filters';
import { ActivityChartList, ActivityIndicatorList, ActivityItemList } from '../filters/ActivityLists';
import { DEFAULT_GROUP_SETTINGS, GroupToolbarButton } from '../filters/GroupToolbarButton';
import { getSummarySettingsForSummaryStyle, SummaryStyleButton } from '../filters/SummaryStyleButton';
import { getActivityQueryVars, PresetFilter } from '../helpers/preset-filters';
import { QueryTemplateBlockState } from './TemplateBlock';

export function FiltersOutput(props: {
    activeBlock: QueryTemplateBlockState | undefined;
    onUpdateGroupType: (groupType: ItemGroupType) => void;
    onUpdateGroupOrdering: (newOrder: string[] | undefined) => void;
    onCustomSettingsChange: (customSettings: SummarizationSettingsInput) => void;
    onIndicatorToggled: (indicatorId: string) => void;
    timelineLoading: boolean;
    summaryLoading: boolean;
}): JSX.Element {
    const [hasData, setHasData] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const showItems = useMemo(
        () =>
            props.activeBlock?.timelineId &&
            (props.activeBlock.preset || props.activeBlock.propertyFilterGroups.length > 0) &&
            props.activeBlock.preset !== PresetFilter.Indicators &&
            props.activeBlock.preset !== PresetFilter.Charts,
        [props.activeBlock],
    );
    const showIndicators = useMemo(
        () => props.activeBlock?.timelineId && props.activeBlock.preset === PresetFilter.Indicators,
        [props.activeBlock],
    );
    const showCharts = useMemo(
        () => props.activeBlock?.timelineId && props.activeBlock.preset === PresetFilter.Charts,
        [props.activeBlock],
    );
    const [subgroupNames, setSubgroupNames] = useState<string[] | undefined>(undefined);

    return (
        <Box
            flex
            pad="xsmall"
            gap="xsmall"
            background={{ color: Colors.background_front }}
            round={{ size: '15px' }}
            border={{ color: props.activeBlock && hasData ? Colors.accent_3 : Colors.border_light, size: '1px' }}
        >
            <Box
                border={{ color: Colors.border_dark, side: 'bottom', size: '1px' }}
                gap="xxsmall"
                pad={{ bottom: 'xxsmall' }}
            >
                <Box direction="row" justify="between">
                    <Text
                        size="14px"
                        weight={500}
                        color={Colors.dark_6}
                        style={{
                            fontFamily: FontFamily.Callout,
                            textTransform: 'uppercase',
                            letterSpacing: '2px',
                        }}
                    >
                        Output
                    </Text>
                    {isLoading && <Spinner size="xsmall" />}
                </Box>
                <Box direction="row" align="center" gap="xsmall">
                    <SummaryStyleButton
                        onCustomSettingsChange={props.onCustomSettingsChange}
                        timelineId={props.activeBlock?.timelineId}
                        timelineLoading={props.timelineLoading}
                        summaryLoading={props.summaryLoading}
                        customSettings={
                            props.activeBlock?.summarizationCustomSettings ||
                            getSummarySettingsForSummaryStyle(SummarizationStyle.Highlights)
                        }
                        disabled={props.activeBlock == null}
                    />
                    <GroupToolbarButton
                        onChange={props.onUpdateGroupType}
                        group={props.activeBlock?.groupSettings || DEFAULT_GROUP_SETTINGS}
                        timelineLoading={props.timelineLoading}
                        subgroupNames={subgroupNames}
                        onOrderingChange={props.onUpdateGroupOrdering}
                        disabled={props.activeBlock == null}
                    />
                </Box>
            </Box>
            <Box overflow={{ vertical: 'auto' }} flex>
                <Box height={{ min: 'max-content', height: 'max-content' }} gap="xsmall">
                    {showItems && (
                        <ActivityItemList
                            timelineId={props.activeBlock!.timelineId!}
                            queryVars={getActivityQueryVars(
                                props.activeBlock!.groupSettings,
                                getActivityFilters(
                                    props.activeBlock!.propertyFilterGroups,
                                    props.activeBlock!.filtersOperator,
                                    props.activeBlock!.graphFilterType,
                                    props.activeBlock!.dateSelection,
                                ),
                            )}
                            onChange={(subGroups) => {
                                setHasData(!!(subGroups && subGroups.length > 0));
                                setSubgroupNames(
                                    subGroups?.map((subGroup) => subGroup.subgroupName).filter((name) => name) as
                                        | string[]
                                        | undefined,
                                );
                            }}
                            selectedItems={new Set<string>()}
                            onItemsSelected={() => {}}
                            onChangeLoading={(loading) => setIsLoading(loading)}
                        />
                    )}
                    {showIndicators && (
                        <ActivityIndicatorList
                            timelineId={props.activeBlock!.timelineId!}
                            onChange={(indicators) =>
                                setHasData(
                                    !!(
                                        indicators &&
                                        (indicators.timelineIndicators.metricBoxes.length > 0 ||
                                            indicators.timelineIndicators.insights.length > 0 ||
                                            indicators.timelineIndicators.groupedInsights.length > 0)
                                    ),
                                )
                            }
                            onChangeLoading={(loading) => setIsLoading(loading)}
                            onIndicatorToggled={props.onIndicatorToggled}
                            selectedIndicators={props.activeBlock!.selectedInsightIds}
                        />
                    )}
                    {showCharts && (
                        <ActivityChartList
                            timelineId={props.activeBlock!.timelineId!}
                            onChange={(charts) => setHasData(!!(charts && charts.timelineMetricCharts.data.length > 0))}
                            onChangeLoading={(loading) => setIsLoading(loading)}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
}
