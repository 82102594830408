// Copyright 2021
// ThatWorks.xyz Limited

import { TimelineCreateDate, TimelineDateType } from '../../../../../__generated__/graphql';

export enum DatePreset {
    Today = 'Today',
    StartOfWeek = 'Start of this week',
    EndOfWeek = 'End of this week',
    OneDay = '1 day',
    OneWeek = '1 week',
    TwoWeeks = '2 weeks',
    OneMonth = '1 month',
    ThreeMonths = '3 months',
    Custom = 'Custom',
}

export interface TimelineDateSelection {
    preset: DatePreset;
    customDaysStr: string;
}

export function datePresetToDays(
    preset: Exclude<DatePreset, DatePreset.Custom | DatePreset.Today | DatePreset.StartOfWeek | DatePreset.EndOfWeek>,
): number {
    switch (preset) {
        case DatePreset.OneDay:
            return 1;
        case DatePreset.OneWeek:
            return 7;
        case DatePreset.TwoWeeks:
            return 14;
        case DatePreset.OneMonth:
            return 30;
        case DatePreset.ThreeMonths:
            return 90;
    }
}

export function daysToDatePreset(number: number): TimelineDateSelection {
    switch (number) {
        case 1:
            return { preset: DatePreset.OneDay, customDaysStr: '' };
        case 7:
            return { preset: DatePreset.OneWeek, customDaysStr: '' };
        case 14:
            return { preset: DatePreset.TwoWeeks, customDaysStr: '' };
        case 30:
            return { preset: DatePreset.OneMonth, customDaysStr: '' };
        case 90:
            return { preset: DatePreset.ThreeMonths, customDaysStr: '' };
        default:
            return { preset: DatePreset.Custom, customDaysStr: number.toString() };
    }
}

export function timelineDateSelectionToTimelineCreateDate(selection: TimelineDateSelection): TimelineCreateDate {
    const userIanaZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (selection.preset === DatePreset.Custom) {
        return {
            type: TimelineDateType.RelativeDaysMinus,
            relativeDays: Number(selection.customDaysStr),
            userIanaZone,
        };
    } else if (selection.preset === DatePreset.Today) {
        return {
            type: TimelineDateType.Today,
            userIanaZone,
        };
    } else if (selection.preset === DatePreset.StartOfWeek) {
        return {
            type: TimelineDateType.StartOfWeek,
            userIanaZone,
        };
    } else if (selection.preset === DatePreset.EndOfWeek) {
        return {
            type: TimelineDateType.EndOfWeek,
            userIanaZone,
        };
    }

    return {
        type: TimelineDateType.RelativeDaysMinus,
        relativeDays: datePresetToDays(selection.preset),
        userIanaZone,
    };
}

export function timelineCreateDateToTimelineDateSelection(timelineDate: TimelineCreateDate): TimelineDateSelection {
    if (timelineDate.type === TimelineDateType.Today) {
        return { preset: DatePreset.Today, customDaysStr: '' };
    } else if (timelineDate.type === TimelineDateType.StartOfWeek) {
        return { preset: DatePreset.StartOfWeek, customDaysStr: '' };
    } else {
        return daysToDatePreset(Number(timelineDate.relativeDays));
    }
}

export function timelineDateTypeToDatePreset(timelineDate: TimelineDateType): DatePreset {
    switch (timelineDate) {
        case TimelineDateType.Today:
            return DatePreset.Today;
        case TimelineDateType.StartOfWeek:
            return DatePreset.StartOfWeek;
        case TimelineDateType.EndOfWeek:
            return DatePreset.EndOfWeek;
        case TimelineDateType.RelativeDaysMinus:
        case TimelineDateType.RelativeDaysPlus:
        case TimelineDateType.Iso:
            return DatePreset.Custom;
    }
}
