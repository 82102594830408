// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName } from '@thatworks/connector-api';
import React from 'react';
import { useTelemetryContext } from './TelemetryContext';

export interface IconProps {
    name: ConnectorName;
    extension?: 'svg' | 'png';
    sizePixels?: string;
    alignSelf?: string;
    opacity?: string;
    style?: React.CSSProperties;
}

export function getIconPath(name: string, extension?: 'svg' | 'png') {
    const ext = extension || 'svg';
    const additional = ext === 'png' ? '-18x18' : '';
    return `/icons/connector-icon-small/${ext}/${name}${additional}.${ext}`;
}

function getIcon(p: IconProps, onError: () => void): JSX.Element {
    switch (p.name) {
        case ConnectorName.NOTION:
            return (
                <img
                    src={getIconPath('notion', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ alignSelf: p.alignSelf, pointerEvents: 'none', opacity: p.opacity, ...p.style }}
                    alt="Notion Icon"
                />
            );
        case ConnectorName.ATLASSIAN:
            return (
                <img
                    src={getIconPath('atlassian', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ alignSelf: p.alignSelf, pointerEvents: 'none', opacity: p.opacity, ...p.style }}
                    alt="Atlassian Icon"
                />
            );
        case ConnectorName.ATLASSIAN_JIRA:
            return (
                <img
                    src={getIconPath('jira', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ alignSelf: p.alignSelf, pointerEvents: 'none', opacity: p.opacity, ...p.style }}
                    alt="Jira Icon"
                />
            );
        case ConnectorName.ATLASSIAN_CONFLUENCE:
            return (
                <img
                    src={getIconPath('confluence', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Confluence Icon"
                />
            );
        case ConnectorName.CLICKUP:
            return (
                <img
                    src={getIconPath('clickup', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Clickup Icon"
                />
            );
        case ConnectorName.GOOGLE:
            return (
                <img
                    src={getIconPath('google', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Google Icon"
                />
            );
        case ConnectorName.GOOGLE_DRIVE:
            return (
                <img
                    src={getIconPath('google-drive', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Google Drive Icon"
                />
            );
        case ConnectorName.GOOGLE_ANALYTICS:
            return (
                <img
                    src={getIconPath('google-analytics', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Google Analytics Icon"
                />
            );
        case ConnectorName.FIGMA:
            return (
                <img
                    src={getIconPath('figma', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Figma Icon"
                />
            );
        case ConnectorName.MIRO:
            return (
                <img
                    src={getIconPath('miro', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Miro Icon"
                />
            );
        case ConnectorName.ASANA:
            return (
                <img
                    src={getIconPath('asana', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Asana Icon"
                />
            );
        case ConnectorName.TOGGL:
            return (
                <img
                    src={getIconPath('toggl', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Toggl Icon"
                />
            );
        case ConnectorName.LINEAR:
            return (
                <img
                    src={getIconPath('linear', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Linear Icon"
                />
            );
        case ConnectorName.SLACK:
            return (
                <img
                    src={getIconPath('slack', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Slack Icon"
                />
            );
        case ConnectorName.GITHUB:
            return (
                <img
                    src={getIconPath('github', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="GitHub Icon"
                />
            );
        case ConnectorName.GITLAB:
            return (
                <img
                    src={getIconPath('gitlab', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="GitLab Icon"
                />
            );
        case ConnectorName.BITBUCKET:
            return (
                <img
                    src={getIconPath('bitbucket', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Bitbucket Icon"
                />
            );
        case ConnectorName.HUBSPOT:
            return (
                <img
                    src={getIconPath('hubspot', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Hubspot Icon"
                />
            );
        case ConnectorName.MONDAY:
            return (
                <img
                    src={getIconPath('monday', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Monday Icon"
                />
            );
        case ConnectorName.TRELLO:
            return (
                <img
                    src={getIconPath('trello', p.extension)}
                    width={p.sizePixels}
                    height={p.sizePixels}
                    style={{ opacity: p.opacity, alignSelf: p.alignSelf, pointerEvents: 'none', ...p.style }}
                    alt="Trello Icon"
                />
            );
        default:
            onError();
            return <></>;
    }
}

export function ConnectorIconSmall(props: IconProps): JSX.Element {
    const { logger } = useTelemetryContext();
    return getIcon(props, () => logger.error(`No icon specified for ${props.name}`));
}
