// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useEffect, useMemo, useState } from 'react';
import { gql } from '../../../../../__generated__';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { FontFamily } from '../../../../../theme';
import { MAGIC_COMPOSER_CTA_BUTTON_HEIGHT } from './MagicComposer';

export const GENERATE_POST_TITLE = gql(/* GraphQL */ `
    query GeneratePostTitle($data: [String!]!) {
        generatePostTitleFromDataSources(data: $data)
    }
`);

export interface SavedQueriesState {
    workspace: { id: string; label: string } | undefined;
}

export function MagicComposerQuery(props: {
    onCloseModal: () => void;
    onSavedQueries: (title: string) => Promise<void>;
    label: string;
    title: string;
    loadingSummary: boolean;
    loadingTitle: boolean;
}): JSX.Element {
    const [title, setTitle] = useState(props.title);
    useEffect(() => {
        setTitle(props.title);
    }, [props.title]);
    const loading = useMemo(
        () => props.loadingSummary || props.loadingTitle,
        [props.loadingTitle, props.loadingSummary],
    );
    return (
        <Box background={Colors.light_2} round="15px" pad="xsmall" width="600px">
            {loading && <Spinner alignSelf="center" />}
            {!loading && (
                <Box>
                    {/* Title and close button */}
                    <Box direction="row" justify="between">
                        <Text style={{ fontFamily: FontFamily.Callout }} size="22px" weight={'bold'}>
                            Saved query
                        </Text>
                        <IconButtonV2
                            icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                            reverse
                            onClick={props.onCloseModal}
                        />
                    </Box>

                    {/* Post title */}
                    <Box
                        direction="row"
                        align="baseline"
                        gap="xsmall"
                        border={{ side: 'bottom', size: '1px', color: Colors.border_dark }}
                        pad={{ vertical: 'xsmall' }}
                    >
                        <Text>Title</Text>
                        <PlainTextInput
                            fontSize="16px"
                            value={title}
                            placeholder="Untitled"
                            autoFocus
                            style={{
                                fontFamily: FontFamily.Mono,
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                letterSpacing: '-0.02em',
                                color: 'unset',
                                background: 'unset',
                                border: `1px solid ${Colors.accent_3}`,
                                padding: '5px 16px',
                                backgroundColor: 'unset',
                                width: '100%',
                            }}
                            width="inherit"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </Box>

                    {/* Save button */}
                    <Box width="max-content" pad={{ top: 'xsmall' }}>
                        <CtaButtonSpinnerV2
                            label={props.label}
                            style={{ padding: '1px 14px', height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                            onClick={() => props.onSavedQueries(title)}
                            disabled={title === ''}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}
