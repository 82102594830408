// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Select, Text } from 'grommet';
import { FormDown } from 'grommet-icons';
import { useMemo, useState } from 'react';
import { AutomationScheduleEvery } from '../../../../../__generated__/graphql';
import { getLabelForSchedule } from '../../../../../components/schedule-label';
import {
    DaysOfWeek,
    getDaysOfWeekFromIsoDay,
    getIsoWeekday,
    HalfHourTimeInterval,
    scheduleToTime,
    TimeInSchedule,
    to24Hour,
} from '../helpers/automation-types';

function ScheduleTimeSelection(props: {
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
}): JSX.Element {
    return (
        <Box direction="row" gap="xsmall" align="baseline">
            <Text>at</Text>
            <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                <Select
                    plain
                    options={Object.values(HalfHourTimeInterval)}
                    value={scheduleToTime(props.time)}
                    onChange={({ option }) => {
                        const { hour, minute } = to24Hour(option);
                        props.onTimeChange({
                            hour,
                            minute,
                            ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        });
                    }}
                    style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                    width={'100px'}
                    icon={<FormDown size="16px" />}
                />
            </Box>
        </Box>
    );
}

function ScheduleDayOfWeekSelection(props: {
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
}): JSX.Element {
    return (
        <Box gap="xsmall" direction="row" align="baseline">
            <Box direction="row" gap="xsmall" align="baseline">
                <Text>on</Text>
                <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                    <Select
                        plain
                        options={Object.values(DaysOfWeek)}
                        value={getDaysOfWeekFromIsoDay(props.isoDayOfWeek)}
                        onChange={({ option }) => {
                            props.onDayChange(getIsoWeekday(option));
                        }}
                        width={'105px'}
                        icon={<FormDown size="16px" />}
                        style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                    />
                </Box>
            </Box>
            <ScheduleTimeSelection time={props.time} onTimeChange={props.onTimeChange} />
        </Box>
    );
}

const MONTHLY_SCHEDULES = [
    AutomationScheduleEvery.MonthFirstDayOfWeek,
    AutomationScheduleEvery.MonthLastDayOfWeek,
    AutomationScheduleEvery.MonthFirstDay,
    AutomationScheduleEvery.MonthLastDay,
    AutomationScheduleEvery.MonthDay,
];

function ScheduleMonthlySelection(props: {
    every: AutomationScheduleEvery;
    onEveryChange: (v: AutomationScheduleEvery) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
    dayOfMonth: number;
    onDayOfMonthChange: (v: number) => void;
}): JSX.Element {
    return (
        <Box gap="xsmall" direction="row" align="baseline">
            <Box direction="row" gap="xsmall" align="baseline">
                <Text>on</Text>
                <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                    <Select
                        plain
                        options={MONTHLY_SCHEDULES.map((v) => ({
                            value: v,
                            label: getLabelForSchedule(v),
                        }))}
                        labelKey={'label'}
                        value={getLabelForSchedule(props.every)}
                        onChange={({ option }) => {
                            props.onEveryChange(option.value);
                        }}
                        width={
                            props.every === AutomationScheduleEvery.MonthFirstDay ||
                            props.every === AutomationScheduleEvery.MonthLastDay
                                ? '230px'
                                : '105px'
                        }
                        icon={<FormDown size="16px" />}
                        style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                    />
                </Box>
            </Box>
            {(props.every === AutomationScheduleEvery.MonthFirstDayOfWeek ||
                props.every === AutomationScheduleEvery.MonthLastDayOfWeek) && (
                <Box direction="row" gap="xsmall" align="baseline">
                    <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                        <Select
                            plain
                            options={Object.values(DaysOfWeek)}
                            value={getDaysOfWeekFromIsoDay(props.isoDayOfWeek)}
                            onChange={({ option }) => {
                                props.onDayChange(getIsoWeekday(option));
                            }}
                            width={'105px'}
                            icon={<FormDown size="16px" />}
                            style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                        />
                    </Box>
                </Box>
            )}
            {props.every === AutomationScheduleEvery.MonthDay && (
                <Box direction="row" gap="xsmall" align="baseline">
                    <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                        <Select
                            plain
                            options={Array.from({ length: 31 }, (_, i) => i + 1)}
                            value={props.dayOfMonth}
                            onChange={({ option }) => props.onDayOfMonthChange(option)}
                            width={'105px'}
                            icon={<FormDown size="16px" />}
                            style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
}

enum AutomationScheduleUi {
    Weekday = 'Weekday',
    Week = 'Week',
    Month = 'Month',
    Day = 'Day',
}

function getLabelForScheduleUi(schedule: AutomationScheduleUi): string {
    switch (schedule) {
        case AutomationScheduleUi.Day:
            return 'Day';
        case AutomationScheduleUi.Week:
            return 'Week';
        case AutomationScheduleUi.Weekday:
            return 'Weekday';
        case AutomationScheduleUi.Month:
            return 'Month';
    }
}

function getScheduleUiFromSchedule(schedule: AutomationScheduleEvery): AutomationScheduleUi {
    switch (schedule) {
        case AutomationScheduleEvery.Day:
            return AutomationScheduleUi.Day;
        case AutomationScheduleEvery.Week:
            return AutomationScheduleUi.Week;
        case AutomationScheduleEvery.WeekDay:
            return AutomationScheduleUi.Weekday;
        case AutomationScheduleEvery.MonthFirstDay:
        case AutomationScheduleEvery.MonthLastDay:
        case AutomationScheduleEvery.MonthFirstDayOfWeek:
        case AutomationScheduleEvery.MonthLastDayOfWeek:
        case AutomationScheduleEvery.MonthDay:
            return AutomationScheduleUi.Month;
    }
}

function getScheduleFromUi(schedule: AutomationScheduleUi): AutomationScheduleEvery {
    switch (schedule) {
        case AutomationScheduleUi.Day:
            return AutomationScheduleEvery.Day;
        case AutomationScheduleUi.Week:
            return AutomationScheduleEvery.Week;
        case AutomationScheduleUi.Weekday:
            return AutomationScheduleEvery.WeekDay;
        case AutomationScheduleUi.Month:
            throw new Error('Not supported, use the specific month component');
    }
}

export function ScheduleRow(props: {
    every: AutomationScheduleEvery;
    onEveryChange: (e: AutomationScheduleEvery) => void;
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
    dayOfMonth: number;
    onDayOfMonthChange: (v: number) => void;
}): JSX.Element {
    const [scheduleInUi, setScheduleInUi] = useState<AutomationScheduleUi>(getScheduleUiFromSchedule(props.every));

    const textDescription = useMemo(() => {
        const res = `Delivers a summary`;
        switch (props.every) {
            case AutomationScheduleEvery.Day:
                return `${res} everyday with data from the previous day.`;
            case AutomationScheduleEvery.Week:
                return `${res} once a week with data from the previous week.`;
            case AutomationScheduleEvery.WeekDay:
                return `${res} every weekday with data from the previous weekday.`;
            case AutomationScheduleEvery.MonthFirstDay:
                return `${res} on the first day of the month with data from the previous month.`;
            case AutomationScheduleEvery.MonthLastDay:
                return `${res} on the last day of the month with data covering a month.`;
            case AutomationScheduleEvery.MonthFirstDayOfWeek:
                return `${res} on the first ${getDaysOfWeekFromIsoDay(
                    props.isoDayOfWeek,
                )} of the month with data from the previous month.`;
            case AutomationScheduleEvery.MonthLastDayOfWeek:
                return `${res} on the last ${getDaysOfWeekFromIsoDay(
                    props.isoDayOfWeek,
                )} of the month with data from the previous month.`;
            case AutomationScheduleEvery.MonthDay:
                return `${res} on the ${
                    props.dayOfMonth === 31 ? `31 (or last day)` : props.dayOfMonth
                } of the month with data from the previous month.`;
        }
    }, [props.dayOfMonth, props.every, props.isoDayOfWeek]);

    return (
        <Box gap="xsmall">
            <Box direction="row" align="baseline" gap="xsmall">
                <Text>Every</Text>
                <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                    <Select
                        plain
                        options={Object.values(AutomationScheduleUi).map((v) => ({
                            value: v,
                            label: getLabelForScheduleUi(v),
                        }))}
                        labelKey={'label'}
                        // value={{ value: props.every, label: getScheduleUiFromSchedule(props.every) }}
                        value={{ value: scheduleInUi, label: getLabelForScheduleUi(scheduleInUi) }}
                        onChange={({ option }) => {
                            setScheduleInUi(option.value);

                            props.onEveryChange(
                                option.value === AutomationScheduleUi.Month
                                    ? AutomationScheduleEvery.MonthFirstDayOfWeek
                                    : getScheduleFromUi(option.value),
                            );
                        }}
                        width={'85px'}
                        icon={<FormDown size="16px" />}
                        style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                    />
                </Box>
            </Box>
            {scheduleInUi === AutomationScheduleUi.Month && (
                <ScheduleMonthlySelection
                    every={props.every}
                    onEveryChange={props.onEveryChange}
                    isoDayOfWeek={props.isoDayOfWeek}
                    onDayChange={props.onDayChange}
                    dayOfMonth={props.dayOfMonth}
                    onDayOfMonthChange={props.onDayOfMonthChange}
                />
            )}
            {props.every !== AutomationScheduleEvery.Week && (
                <ScheduleTimeSelection onTimeChange={props.onTimeChange} time={props.time} />
            )}
            {props.every === AutomationScheduleEvery.Week && (
                <ScheduleDayOfWeekSelection
                    onTimeChange={props.onTimeChange}
                    time={props.time}
                    isoDayOfWeek={props.isoDayOfWeek}
                    onDayChange={props.onDayChange}
                />
            )}
            <Text size="small">{textDescription}</Text>
        </Box>
    );
}
