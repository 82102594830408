// Copyright 2021
// ThatWorks.xyz Limited

import { gql } from '../../../../../__generated__';

export const GET_TEMPLATES = gql(/* GraphQL */ `
    query GetWorkspacePostTemplates($withAutomations: Boolean) {
        workspacePostTemplates(withAutomations: $withAutomations) {
            id
            textBlocks {
                markdownText
                displayIndex
            }
            queryBlocks {
                preset
                filterName
                displayIndex
                summarizationSettings {
                    changesSummaryEnabled
                    changesLevelOfDetail
                    changesSummaryFormat
                    changesAlsoGroupByItemType
                    changesSummaryFields
                    commentsSummaryEnabled
                    commentsSummaryLength
                    customFormattingPrompt
                    propertyNamesToSummarize
                }
                selectedIndicatorIds
                grouping {
                    groupType
                    subgroupOrdering
                }
                date {
                    type
                    isoDate
                    relativeDays
                    userIanaZone
                }
                scopes {
                    connector
                    itemName
                    itemUuid
                    hierarchyType
                }
                filters {
                    filters {
                        action {
                            in
                        }
                        actors {
                            in
                        }
                        changeType {
                            in
                            eq
                            neq
                            since {
                                dateQuery {
                                    type
                                    isoDate
                                    relativeDays
                                    userIanaZone
                                }
                                changeCount {
                                    eq
                                    neq
                                }
                            }
                        }
                        feature {
                            in
                            eq {
                                feature
                                score
                            }
                            neq {
                                feature
                                score
                            }
                            gt {
                                feature
                                score
                            }
                            gte {
                                feature
                                score
                            }
                            lt {
                                feature
                                score
                            }
                            lte {
                                feature
                                score
                            }
                        }
                        properties {
                            in {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            eq {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            neq {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            gt {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            gte {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            lt {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            lte {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            regex {
                                property
                                value
                            }
                            empty {
                                property
                                value
                            }
                        }
                        type {
                            in
                        }
                        connectorItemType {
                            in
                            eq
                            neq
                            regex
                        }
                        userMention {
                            in
                            eq
                        }
                        title {
                            in
                            eq
                            neq
                            regex
                            inc
                            exc
                        }
                        comment {
                            in
                            eq
                            neq
                            regex
                            empty
                            inc
                            exc
                        }
                    }
                    operator
                    graphFilterType
                }
            }
            title
            userAbilities
            automation {
                schedule {
                    every
                    hour
                    ianaTimeZone
                    isoDayOfWeek
                    dayOfMonth
                    minute
                }
                destinations {
                    workspaceId
                    workspaceName
                }
                enabled
            }
            slackNotifications {
                teamId
                teamName
                channels {
                    id
                    name
                    private
                }
            }
            slackChannelMention
            emailNotifications
            summaryCustomFormattingPrompt
        }
    }
`);

export const GET_TEMPLATE = gql(/* GraphQL */ `
    query WorkspacePostTemplate($workspacePostTemplateId: ID!) {
        workspacePostTemplate(id: $workspacePostTemplateId) {
            id
            textBlocks {
                markdownText
                displayIndex
            }
            queryBlocks {
                preset
                filterName
                displayIndex
                summarizationSettings {
                    changesSummaryEnabled
                    changesLevelOfDetail
                    changesSummaryFormat
                    changesAlsoGroupByItemType
                    changesSummaryFields
                    commentsSummaryEnabled
                    commentsSummaryLength
                    customFormattingPrompt
                    propertyNamesToSummarize
                }
                selectedIndicatorIds
                grouping {
                    groupType
                    subgroupOrdering
                }
                date {
                    type
                    isoDate
                    relativeDays
                    userIanaZone
                }
                scopes {
                    connector
                    itemName
                    itemUuid
                    hierarchyType
                }
                filters {
                    filters {
                        action {
                            in
                        }
                        actors {
                            in
                        }
                        changeType {
                            in
                            eq
                            neq
                            since {
                                dateQuery {
                                    type
                                    isoDate
                                    relativeDays
                                    userIanaZone
                                }
                                changeCount {
                                    eq
                                    neq
                                }
                            }
                        }
                        feature {
                            in
                            eq {
                                feature
                                score
                            }
                            neq {
                                feature
                                score
                            }
                            gt {
                                feature
                                score
                            }
                            gte {
                                feature
                                score
                            }
                            lt {
                                feature
                                score
                            }
                            lte {
                                feature
                                score
                            }
                        }
                        properties {
                            in {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            eq {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            neq {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            gt {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            gte {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            lt {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            lte {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            regex {
                                property
                                value
                            }
                            empty {
                                property
                                value
                            }
                        }
                        type {
                            in
                        }
                        connectorItemType {
                            in
                            eq
                            neq
                            regex
                        }
                        userMention {
                            in
                            eq
                        }
                        title {
                            in
                            eq
                            neq
                            regex
                            inc
                            exc
                        }
                        comment {
                            in
                            eq
                            neq
                            regex
                            empty
                            inc
                            exc
                        }
                    }
                    operator
                    graphFilterType
                }
            }
            title
            userAbilities
            automation {
                schedule {
                    every
                    hour
                    ianaTimeZone
                    isoDayOfWeek
                    dayOfMonth
                    minute
                }
                destinations {
                    workspaceId
                    workspaceName
                }
                enabled
            }
            slackNotifications {
                teamId
                teamName
                channels {
                    id
                    name
                    private
                }
            }
            slackChannelMention
            emailNotifications
            summaryCustomFormattingPrompt
        }
    }
`);

export const GET_TEMPLATE_SNAPSHOT_DATE = gql(/* GraphQL */ `
    query GetTemplateSnapshot($id: ID!) {
        workspacePostTemplateSnapshotIsoDate(id: $id)
    }
`);

export const CREATE_TEMPLATE_SNAPSHOT = gql(/* GraphQL */ `
    mutation CreateTemplateSnapshot($id: ID!) {
        workspacePostTemplateSnapshotCreate(id: $id)
    }
`);

export const RESTORE_TEMPLATE_SNAPSHOT = gql(/* GraphQL */ `
    mutation RestoreTemplateSnapshot($id: ID!) {
        workspacePostTemplateSnapshotRestore(id: $id)
    }
`);

export const CREATE_TEMPLATE = gql(/* GraphQL */ `
    mutation CreateTemplate($template: WorkspacePostTemplateCreateInput!) {
        workspacePostTemplateCreate(template: $template)
    }
`);

export const UPDATE_TEMPLATE = gql(/* GraphQL */ `
    mutation WorkspacePostTemplateUpdate($id: ID!, $template: WorkspacePostTemplateUpdateInput!) {
        workspacePostTemplateUpdate(id: $id, template: $template) {
            id
        }
    }
`);

export const DELETE_TEMPLATE = gql(/* GraphQL */ `
    mutation WorkspacePostTemplateDelete($workspacePostTemplateDeleteId: ID!) {
        workspacePostTemplateDelete(id: $workspacePostTemplateDeleteId)
    }
`);

export const TRIGGER_TEMPLATE_AUTOMATION = gql(/* GraphQL */ `
    mutation WorkspacePostTemplateTriggerAutomation($templateId: ID!) {
        workspacePostTemplateTriggerAutomation(id: $templateId)
    }
`);

export const CREATE_UPDATE_TEMPLATE_DRAFT = gql(/* GraphQL */ `
    mutation CreateUpdateTemplateDraft($template: WorkspacePostTemplateUpdateInput!) {
        workspacePostTemplateDraftCreateUpdate(template: $template)
    }
`);

export const DELETE_TEMPLATE_DRAFT = gql(/* GraphQL */ `
    mutation DeleteTemplateDraft {
        workspacePostTemplateDraftDelete
    }
`);

export const GET_TEMPLATE_DRAFT = gql(/* GraphQL */ `
    query GetTemplateDraft {
        workspacePostTemplateDraft {
            id
            textBlocks {
                markdownText
                displayIndex
            }
            queryBlocks {
                preset
                filterName
                displayIndex
                summarizationSettings {
                    changesSummaryEnabled
                    changesLevelOfDetail
                    changesSummaryFormat
                    changesAlsoGroupByItemType
                    changesSummaryFields
                    commentsSummaryEnabled
                    commentsSummaryLength
                    customFormattingPrompt
                    propertyNamesToSummarize
                }
                selectedIndicatorIds
                grouping {
                    groupType
                    subgroupOrdering
                }
                date {
                    type
                    isoDate
                    relativeDays
                    userIanaZone
                }
                scopes {
                    connector
                    itemName
                    itemUuid
                    hierarchyType
                }
                filters {
                    filters {
                        action {
                            in
                        }
                        actors {
                            in
                        }
                        changeType {
                            in
                            eq
                            neq
                            since {
                                dateQuery {
                                    type
                                    isoDate
                                    relativeDays
                                    userIanaZone
                                }
                                changeCount {
                                    eq
                                    neq
                                }
                            }
                        }
                        feature {
                            in
                            eq {
                                feature
                                score
                            }
                            neq {
                                feature
                                score
                            }
                            gt {
                                feature
                                score
                            }
                            gte {
                                feature
                                score
                            }
                            lt {
                                feature
                                score
                            }
                            lte {
                                feature
                                score
                            }
                        }
                        properties {
                            in {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            eq {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            neq {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            gt {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            gte {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            lt {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            lte {
                                property
                                value {
                                    isUser
                                    statusCategory
                                    normalizedPriority
                                    dateQuery {
                                        type
                                        isoDate
                                        relativeDays
                                        userIanaZone
                                    }
                                    rawValue
                                }
                            }
                            regex {
                                property
                                value
                            }
                            empty {
                                property
                                value
                            }
                        }
                        type {
                            in
                        }
                        connectorItemType {
                            in
                            eq
                            neq
                            regex
                        }
                        userMention {
                            in
                            eq
                        }
                        title {
                            in
                            eq
                            neq
                            regex
                            inc
                            exc
                        }
                        comment {
                            in
                            eq
                            neq
                            regex
                            empty
                            inc
                            exc
                        }
                    }
                    operator
                    graphFilterType
                }
            }
            title
            userAbilities
            automation {
                schedule {
                    every
                    hour
                    ianaTimeZone
                    isoDayOfWeek
                    dayOfMonth
                    minute
                }
                destinations {
                    workspaceId
                    workspaceName
                }
                enabled
            }
            slackNotifications {
                teamId
                teamName
                channels {
                    id
                    name
                    private
                }
            }
            slackChannelMention
            emailNotifications
            summaryCustomFormattingPrompt
        }
    }
`);
