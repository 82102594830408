// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation, useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { InlineInsightPillNodeDataBase } from '@thatworks/shared-frontend/prosemirror';
import { Box, BoxProps, Skeleton, Text, Tip } from 'grommet';
import { Down, StatusWarning, Up } from 'grommet-icons';
import objectHash from 'object-hash';
import { useCallback, useMemo, useState } from 'react';
import { gql } from '../../../../__generated__';
import {
    ActivityItemFilterOperator,
    GetHomeScopesQuery,
    GraphFilterType,
    ItemGroupType,
    ItemSubgroup,
    SummarizationSettingsInput,
    SummarizationStyle,
    SummarySection,
} from '../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import { IconButtonV2 } from '../../../../components/IconButton';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { Heart } from '../../../../icons/Heart';
import {
    BlockType,
    CREATE_TIMELINE_FROM_SCOPES,
    GET_ACTIVITY,
    TemplateBlockSummaryData,
} from '../magic-composer/components/TemplateBlock';
import { FrontendSummarizedActivityNodeParser } from '../magic-composer/filters/activity-node-parser';
import { getSummarySettingsForSummaryStyle } from '../magic-composer/filters/SummaryStyleButton';
import {
    TimelineDateSelection,
    timelineDateSelectionToTimelineCreateDate,
} from '../magic-composer/filters/timeline-date-selection';
import { TemplatePreview } from '../templates/components/TemplatePreview';
import { InlineInsightPillOverflow } from '../ws/components/pm-nodes/InlineInsightPillNode';
import { TaskPreviewInfo } from '../ws/components/pm-nodes/TaskPreviewNode';

export function HomeScopeSummarySkeleton(): JSX.Element {
    return (
        <Box pad={{ vertical: 'xsmall' }} animation={{ type: 'pulse', duration: 800, size: 'small' }} gap={'xsmall'}>
            <Skeleton width="60%" height="16px" colors={{ light: [Colors.border_light] }} round="4px" />
            <Skeleton width="12%" height="16px" colors={{ light: [Colors.border_light] }} round="4px" />
            <Skeleton width="20%" height="16px" colors={{ light: [Colors.border_light] }} round="4px" />
            <Skeleton width="20%" height="16px" colors={{ light: [Colors.border_light] }} round="4px" />
            <Skeleton width="20%" height="16px" colors={{ light: [Colors.border_light] }} round="4px" />
            <Skeleton width="40%" height="16px" colors={{ light: [Colors.border_light] }} round="4px" />
        </Box>
    );
}

export function getHomePageSummarySettings(connector: ConnectorName): SummarizationSettingsInput {
    const summarySettings: SummarizationSettingsInput = {
        ...getSummarySettingsForSummaryStyle(
            connector === ConnectorName.SLACK ? SummarizationStyle.Discussions : SummarizationStyle.Highlights,
        ),
        changesAlsoGroupByItemType: false,
        hidePills: true,
    };
    return summarySettings;
}

function Summary(props: {
    scope: GetHomeScopesQuery['homeScopes']['scopes'][number];
    sessionCacheId: string;
    fromDate: TimelineDateSelection;
}): JSX.Element | null {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const [summaryData, setSummaryData] = useState<TemplateBlockSummaryData | undefined>();

    const getSummaryVariables = useCallback(
        (timelineId: string) => {
            const summarySettings = getHomePageSummarySettings(props.scope.scope.connector as ConnectorName);
            const variables = {
                timelineId,
                filters: {
                    graphFilterType: GraphFilterType.Full,
                    operator: ActivityItemFilterOperator.Or,
                    filters: [{}],
                },
                grouping: {
                    groupType: ItemGroupType.None,
                },
                summarize: {
                    summarize: true,
                    settings: summarySettings,
                },
            };
            const idForCache = objectHash(variables);
            return {
                ...variables,
                idForCache,
            };
        },
        [props.scope.scope.connector],
    );

    const [getConnectorsScopesTimeline, { loading: timelineLoading }] = useMutation(CREATE_TIMELINE_FROM_SCOPES, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to fetch timeline` });
            logger.error(error.message);
        },
        onCompleted: async (data) => {
            refetch({
                ...getSummaryVariables(data.timelineCreateFromScopes),
            });
        },
    });

    const { loading: activityLoading, refetch } = useQuery(GET_ACTIVITY, {
        variables: getSummaryVariables(props.scope.timelineId),
        onCompleted: (data) => {
            setSummaryData({
                type: 'activity',
                activity: {
                    title: data.timelineActivity.title,
                    items: data.timelineActivity.items,
                    groups: data.timelineActivity.groups.map((g) => ({
                        type: g.type,
                        subgroups: g.subgroups.map((s) => {
                            const ss: ItemSubgroup = {
                                ids: s.ids,
                                name: s.name,
                                props: s.props,
                                summary: s.summary
                                    ? {
                                          summary: {
                                              sections: s.summary.summary.sections.map((section) =>
                                                  section.map((subsection) => {
                                                      const sumSection: SummarySection = {
                                                          markdown: subsection.markdown,
                                                          newRowForPills: true,
                                                          pills: subsection.pills,
                                                      };
                                                      return sumSection;
                                                  }),
                                              ),
                                          },
                                      }
                                    : undefined,
                            };
                            return ss;
                        }),
                    })),
                },
            });
        },
        onError: ({ message, graphQLErrors }) => {
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                    switch (err.extensions?.code) {
                        case 'CACHE_INVALID':
                            getConnectorsScopesTimeline({
                                variables: {
                                    cacheSessionKey: props.sessionCacheId,
                                    fromDate: timelineDateSelectionToTimelineCreateDate(props.fromDate),
                                    scopes: [
                                        {
                                            connector: props.scope.scope.connector,
                                            hierarchyType: props.scope.scope.hierarchyType,
                                            id: props.scope.scope.id,
                                            itemName: props.scope.scope.itemName,
                                            itemUuid: props.scope.scope.itemUuid,
                                        },
                                    ],
                                },
                            });
                            return;
                    }
                }
            }

            postErrorMessage({ title: 'Error', shortDesc: `Failed to summarize` });
            logger.error(message);
        },
    });

    if (activityLoading || timelineLoading) {
        return <HomeScopeSummarySkeleton />;
    }

    if (!summaryData) {
        return (
            <Box pad={{ vertical: 'xsmall' }} direction="row" gap="xxsmall" align="center">
                <StatusWarning size="14px" />
                <Text size="small">Failed to get summary.</Text>
            </Box>
        );
    }

    return (
        <TemplatePreview
            blocks={[
                {
                    id: '0',
                    type: BlockType.Query,
                    selectedInsightIds: [],
                    title: '',
                    summary: summaryData,
                },
            ]}
            editable={false}
        />
    );
}

function SummaryPreview(props: { scope: GetHomeScopesQuery['homeScopes']['scopes'][number] }): JSX.Element | null {
    const [pillData] = useState(() => {
        const pills: InlineInsightPillNodeDataBase<TaskPreviewInfo>[] = [];
        const parser = new FrontendSummarizedActivityNodeParser();
        const parsed = parser.parseActivity('', props.scope.activity);
        parsed.forEach((p) => {
            if (p.type === 'table') {
                p.rows.forEach((r) =>
                    r.cells.forEach((c) =>
                        c.content.forEach((cont) => {
                            if (cont.type === 'paragraph') {
                                cont.content.forEach((para) => {
                                    if (para.type === 'inline-insight-pill') {
                                        Array.isArray(para.data)
                                            ? para.data.forEach((d) => pills.push(d))
                                            : pills.push(para.data);
                                    }
                                });
                            }
                        }),
                    ),
                );
            } else if (p.type === 'paragraph') {
                p.content.forEach((para) => {
                    if (para.type === 'inline-insight-pill') {
                        Array.isArray(para.data) ? para.data.forEach((d) => pills.push(d)) : pills.push(para.data);
                    }
                });
            }
        });

        return pills;
    });

    return <InlineInsightPillOverflow data={pillData} />;
}

const TOTAL_SIGNAL_BARS = [0, 1, 2, 3, 4];

function UpdatesMeter(props: {
    numUpdates: number;
    minNumUpdates: number;
    maxNumUpdates: number;
    keyId: string;
    boxProps?: BoxProps;
}): JSX.Element {
    const numBars = useMemo(() => {
        // normalize numUpdates within the minNumUpdates and maxNumUpdates range
        const normalized = (props.numUpdates - props.minNumUpdates) / (props.maxNumUpdates - props.minNumUpdates);
        const num = Math.round(normalized * TOTAL_SIGNAL_BARS.length);
        return num;
    }, [props.maxNumUpdates, props.minNumUpdates, props.numUpdates]);

    return (
        <Tip content={'Amount of activity and updates'}>
            <Box
                direction="row"
                gap="2px"
                align="center"
                pad={{ vertical: '4px', horizontal: '4px' }}
                background={Colors.background_front}
                round="5px"
                height={'max-content'}
                {...props.boxProps}
            >
                {TOTAL_SIGNAL_BARS.map((_, vi) => (
                    <span
                        style={{
                            width: '4px',
                            height: '8px',
                            borderRadius: '2px',
                            backgroundColor: vi <= numBars ? Colors.status_ok : Colors.status_disabled,
                            borderColor: Colors.status_disabled,
                            borderWidth: '1px',
                            borderStyle: 'solid',
                        }}
                        key={`signal-${props.keyId}-${vi}`}
                    />
                ))}
            </Box>
        </Tip>
    );
}

const FAVORITE_COLOR = '#ff4081';

const ADD_FAVORITE_SCOPE = gql(/* GraphQL */ `
    mutation AddFavoriteScope($scopeId: String!) {
        homeAddFavoriteScope(scopeId: $scopeId)
    }
`);

const REMOVE_FAVORITE_SCOPE = gql(/* GraphQL */ `
    mutation RemoveFavoriteScope($scopeId: String!) {
        homeRemoveFavoriteScope(scopeId: $scopeId)
    }
`);

export function ScopeSummary(props: {
    scope: GetHomeScopesQuery['homeScopes']['scopes'][number];
    sessionCacheId: string;
    fromDate: TimelineDateSelection;
    defaultOpen?: boolean;
    minNumUpdates: number;
    maxNumUpdates: number;
    favorite: boolean;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const [opened, setOpened] = useState(props.defaultOpen ?? false);
    const [favorite, setFavorite] = useState(props.favorite);

    const [addFavoriteScope] = useMutation(ADD_FAVORITE_SCOPE, {
        onError: (error) => {
            logger.error(error.message);
        },
    });
    const [removeFavoriteScope] = useMutation(REMOVE_FAVORITE_SCOPE, {
        onError: (error) => {
            logger.error(error.message);
        },
    });

    return (
        <Box
            border={{ color: opened ? Colors.border_dark : Colors.border_light }}
            round="10px"
            background={{ color: Colors.background_back }}
            elevation={opened ? 'small' : 'xsmall'}
            animation={{ type: 'fadeIn', duration: 400 }}
        >
            <Box
                direction="row"
                align="center"
                gap="xxsmall"
                justify="between"
                pad={{ horizontal: 'small', vertical: 'small' }}
            >
                <Box direction="row" align="center" gap="xxsmall">
                    <ConnectorIconSmall sizePixels="14px" name={props.scope.scope.connector as ConnectorName} />{' '}
                    <Text>{props.scope.scope.itemName}</Text>
                </Box>
                <Box direction="row" align="center" gap="xxsmall">
                    <IconButtonV2
                        icon={(hover) => (
                            <Heart size="18px" color={hover || favorite ? FAVORITE_COLOR : Colors.border_light} />
                        )}
                        tooltip="Always show, even if no updates"
                        onClick={() => {
                            if (favorite) {
                                removeFavoriteScope({ variables: { scopeId: props.scope.scope.itemUuid } });
                            } else {
                                addFavoriteScope({ variables: { scopeId: props.scope.scope.itemUuid } });
                            }
                            setFavorite(!favorite);
                        }}
                    />
                    <UpdatesMeter
                        keyId={props.scope.scope.id}
                        maxNumUpdates={props.maxNumUpdates}
                        minNumUpdates={props.minNumUpdates}
                        numUpdates={props.scope.numItems}
                    />
                </Box>
            </Box>
            <Box
                pad={{ horizontal: 'small' }}
                border={{ side: 'top', color: opened ? Colors.border_dark : Colors.border_light }}
            >
                <Box pad={{ top: '23px', left: '1px' }}>
                    <SummaryPreview scope={props.scope} />
                </Box>
                {opened && (
                    <Summary scope={props.scope} sessionCacheId={props.sessionCacheId} fromDate={props.fromDate} />
                )}
                <Box pad={{ vertical: 'xsmall' }}>
                    <IconButtonV2
                        icon={(hover) =>
                            opened ? (
                                <Up color={hover ? Colors.accent_3 : undefined} size="12px" />
                            ) : (
                                <Down color={hover ? Colors.accent_3 : undefined} size="12px" />
                            )
                        }
                        onClick={() => setOpened(!opened)}
                        label={(hover) => (
                            <Text color={hover ? Colors.accent_3 : undefined} size="14px">
                                {opened ? `Hide summary` : `See summary`}
                            </Text>
                        )}
                    />
                </Box>
            </Box>
        </Box>
    );
}
