// Copyright 2021
// ThatWorks.xyz Limited

import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Events } from '@thatworks/shared-frontend/metrics';
import { Pages } from '@thatworks/shared-frontend/pages';
import { Box, Button, Text } from 'grommet';
import { History } from 'grommet-icons';
import { useEffect, useRef } from 'react';
import { BasicPage3, BasicPage3NavFunction } from '../../../../components/BasicPage3';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import Divider from '../../../../components/Divider';
import { TwHeading } from '../../../../components/TwHeading';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';
import { FontFamily } from '../../../../theme';
import { WorkspacePostContent } from '../ws/WorkspacePostContent';
import { ONBOARD_EXAMPLE_POST } from './example-config';

const DEFAULT_TEXT_FADEIN_TIME = 1000;

export function RecapTextIcon(props: { plural?: boolean }): JSX.Element {
    return (
        <Box
            direction="row"
            pad={{ vertical: '2px', horizontal: '6px' }}
            round="5px"
            background="background-back"
            align="center"
            border={{ color: 'border' }}
        >
            <History size="16px" color="brand" />
            <Text weight={'bold'} color="brand">
                &nbsp;{props.plural ? 'Recaps' : 'Recap'}
            </Text>
        </Box>
    );
}

export function OnboardWelcome(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const connectRef = useRef<(HTMLButtonElement & HTMLAnchorElement) | null>(null);
    const navigate = useNavNoRerender();
    const { logEvent } = useStatsigClient();

    useEffect(() => {
        logEvent(Events.FrontendOnboardWelcomePageLoaded);
    }, [logEvent]);

    return (
        <BasicPage3 browserPageTitle={'Welcome'} onNav={props.onNav} onboarding>
            <Box gap="small" fill="horizontal" pad={{ horizontal: 'medium', vertical: 'small' }}>
                <Box
                    width="large"
                    height={{ min: 'max-content' }}
                    gap="small"
                    animation={{ type: 'fadeIn', duration: DEFAULT_TEXT_FADEIN_TIME }}
                >
                    <TwHeading level="1">Welcome!</TwHeading>
                    <Box gap="small" animation={{ type: 'fadeIn', duration: DEFAULT_TEXT_FADEIN_TIME, delay: 1000 }}>
                        <Box gap="xsmall" height={{ min: 'max-content' }}>
                            <Box>
                                <Text color="brand" weight="bold">
                                    Start creating easy summaries of work you care about: 📚docs, 🏃 sprints, 📝 tasks,
                                    💼 projects, 📈 metrics from all your tools in one place.
                                </Text>
                            </Box>
                        </Box>
                        <Button
                            ref={connectRef}
                            primary
                            label={'Connect your tools →'}
                            style={{ width: '270px' }}
                            onClick={async () => navigate(`../${Pages.app.subs.connect.root}`)}
                        />
                    </Box>
                </Box>

                <Box gap="small" animation={{ type: 'fadeIn', duration: DEFAULT_TEXT_FADEIN_TIME, delay: 1500 }}>
                    <Divider type="horizontal" color="border" />
                    <Box width="large" height={{ min: 'max-content' }} gap="xsmall">
                        <Box direction="row" align="center">
                            <Text>
                                Here's an example with data from{' '}
                                <span>
                                    <ConnectorIconSmall name={ConnectorName.GOOGLE_DRIVE} sizePixels="16px" />
                                </span>{' '}
                                <span>
                                    <ConnectorIconSmall name={ConnectorName.ATLASSIAN_JIRA} sizePixels="16px" />
                                </span>{' '}
                                <span>
                                    <ConnectorIconSmall name={ConnectorName.GITHUB} sizePixels="16px" />
                                </span>{' '}
                                <span>
                                    <ConnectorIconSmall name={ConnectorName.CLICKUP} sizePixels="16px" />
                                </span>{' '}
                            </Text>
                        </Box>
                    </Box>
                    <Box
                        height={{ min: 'max-content' }}
                        width="720px"
                        pad="small"
                        round="20px"
                        border={{ color: Colors.neutral_1 }}
                        elevation="large"
                        gap="xxsmall"
                    >
                        <Box gap="2px">
                            <TwHeading
                                level={3}
                                style={{
                                    fontFamily: FontFamily.Standard,
                                    fontWeight: 'bold',
                                    letterSpacing: '0.02em',
                                }}
                                color={Colors.black}
                            >
                                Weekly Update
                            </TwHeading>
                        </Box>
                        <WorkspacePostContent content={JSON.stringify(ONBOARD_EXAMPLE_POST)} />
                    </Box>
                    <br />
                </Box>
            </Box>
        </BasicPage3>
    );
}
