// Copyright 2021
// ThatWorks.xyz Limited

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { TelemetryContextProvider } from './components/TelemetryContext';

const container = document.getElementById('root');
if (!container) {
    throw new Error('Root element not found');
}
const root = createRoot(container);
root.render(
    // Disabled global strict mode as by design it loads components
    // twice which creates false auth errors and api calls
    // <React.StrictMode>
    <TelemetryContextProvider>
        <App />
    </TelemetryContextProvider>,
    // </React.StrictMode>,
);
