// Copyright 2021
// ThatWorks.xyz Limited

import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, DropButton } from 'grommet';
import { Clone, Down, Edit, Services, Trash } from 'grommet-icons';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetWorkspacePostTemplatesQuery, WorkspacePostUserAbilities } from '../../../../../__generated__/graphql';
import DropdownButton from '../../../../../components/DropdownButton';
import { CreationMode } from '../../magic-composer/components/MagicComposerBuilder';

export function TemplateOptions(props: {
    template: GetWorkspacePostTemplatesQuery['workspacePostTemplates'][0];
    onDeleteTemplate: () => void;
    onUpdateTemplate: () => void;
    onTriggerAutomation: () => void;
}): JSX.Element {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const goToMagicComposer = useCallback(
        async (mode: CreationMode) => {
            let path = `${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${mode}`;
            navigate(`${path}&${QueryParams.Template}=${props.template.id}`);
        },
        [navigate, props.template.id],
    );
    return (
        <DropButton
            icon={
                <Down
                    size="small"
                    onClick={() => {
                        setOpen(true);
                    }}
                />
            }
            style={{ padding: '0px' }}
            dropContent={
                <Box background="transparent" direction="row">
                    <Box elevation="medium" background="brand" round="5px" pad={{ top: '10px', bottom: '10px' }}>
                        {/* Trigger automation button */}
                        {props.template.automation && (
                            <DropdownButton
                                label={'Trigger Automation'}
                                icon={Services}
                                onClick={async () => {
                                    props.onTriggerAutomation();
                                    setOpen(false);
                                }}
                            />
                        )}
                        {/* Clone automation */}
                        {props.template.automation && (
                            <DropdownButton
                                label={'Clone'}
                                icon={Clone}
                                onClick={async () => {
                                    setOpen(false);
                                    goToMagicComposer(CreationMode.Automation);
                                }}
                            />
                        )}
                        {/* Edit automation */}
                        {props.template.userAbilities.includes(WorkspacePostUserAbilities.CanEdit) && (
                            <DropdownButton
                                label={'Edit'}
                                icon={Edit}
                                onClick={async () => {
                                    props.onUpdateTemplate();
                                    setOpen(false);
                                }}
                            />
                        )}
                        {/* Delete automation */}
                        {props.template.userAbilities.includes(WorkspacePostUserAbilities.CanDelete) && (
                            <DropdownButton
                                label={'Delete'}
                                icon={Trash}
                                onClick={async () => {
                                    props.onDeleteTemplate();
                                    setOpen(false);
                                }}
                            />
                        )}
                    </Box>
                </Box>
            }
            dropAlign={{ left: 'right', top: 'bottom' }}
            dropProps={{
                background: 'transparent',
                elevation: 'none',
                onEsc: () => {
                    setOpen(false);
                },
                onClickOutside: () => {
                    setOpen(false);
                },
            }}
            open={open}
        />
    );
}
