// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, BoxProps, Button, Spinner, Text } from 'grommet';
import { Icon } from 'grommet-icons';
import { useState } from 'react';

export default function DropdownButton(props: {
    icon?: Icon;
    label: string;
    size?: string;
    onClick?: () => Promise<void>;
    active?: boolean;
    boxProps?: BoxProps;
}) {
    const [loading, setLoading] = useState(false);

    return (
        <Button
            onClick={async () => {
                if (props.onClick) {
                    setLoading(true);
                    await props.onClick();
                    setLoading(false);
                }
            }}
            plain
        >
            {({ hover }) => (
                <Box
                    direction="row"
                    gap="xsmall"
                    align="center"
                    background={hover || props.active ? Colors.accent_2 : undefined}
                    pad={{ left: 'small', right: 'small', top: '10px', bottom: '10px' }}
                    {...props.boxProps}
                >
                    {props.icon && <props.icon size={props.size || '17px'} />}
                    {loading && <Spinner size="xsmall" color={Colors.light_2} />}
                    {!loading && (
                        <Text size="13px">
                            <strong>{props.label}</strong>
                        </Text>
                    )}
                </Box>
            )}
        </Button>
    );
}
