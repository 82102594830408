// Copyright 2021
// ThatWorks.xyz Limited

import {
    ActivityItemFilterOperator,
    ActivityItemFiltersInput,
    ActorFilterInput,
    ActorPlaceholder,
    ChangeActionType,
    ChangeActionTypeFilterInput,
    ChangeType,
    ChangeTypeFilterInput,
    ConnectorItemTypeFilterInput,
    GraphFilterType,
    ItemCommentFilterInput,
    ItemTitleFilterInput,
    ItemType,
    ItemTypeFilterInput,
    PropertyFieldValueInput,
    StatusCategory,
    TimelineDateType,
} from '../../../../../../__generated__/graphql';
import { isTimePeriodOption } from '../filter-toolbar-button/DateInput';
import { Operator, Property, PropertyFilterGroup, PropertyFilterSelection } from '../filter-toolbar-button/helpers';
import { DatePreset, datePresetToDays, TimelineDateSelection } from '../timeline-date-selection';

export const PROPERTY_FILTER_OPTIONS = [
    Property.Assignee,
    Property.Description,
    Property.EndDate,
    Property.Priority,
    Property.Status,
    Property.StartDate,
    Property.StatusCategory,
    Property.Tag,
];
const ITEM_TYPES_OPTIONS = [Property.TypeOfObject];
const CONNECTOR_ITEM_TYPES_OPTIONS = [Property.TypeOfItem];
const CHANGE_TYPE_OPTIONS = [Property.Changes];
const CHANGE_ACTION_TYPE_OPTIONS = [Property.Action];
const UPDATED_BY_TYPE_OPTIONS = [Property.UpdatesBy];
const TITLE_OPTIONS = [Property.Title];
const COMMENT_OPTIONS = [Property.Comment];
const ROOT_VALUE_OPERATORS: (Operator | undefined)[] = [
    Operator.Empty,
    Operator.Regex,
    Operator.From,
    Operator.Inc,
    Operator.Exc,
];

export function isCustomProperty(property: string): boolean {
    // If the property isn't pre-defined then it is a custom value
    return !Object.values<string>(Property).includes(property);
}

function filterOptions(
    propertyFilters: PropertyFilterSelection[],
    options: { type: 'predefined_properties'; props: Property[] } | { type: 'custom_properties' },
): PropertyFilterSelection[] {
    return propertyFilters.filter((filter) => {
        let propsOk = false;
        if (options.type === 'predefined_properties') {
            propsOk =
                filter.property !== undefined &&
                !isCustomProperty(filter.property) &&
                options.props.includes(filter.property as Property);
        } else if (options.type === 'custom_properties') {
            propsOk = filter.property !== undefined && filter.property.length > 0 && isCustomProperty(filter.property);
        }

        return filter.operator && filter.value && filter.property && propsOk;
    });
}

export function getItemTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ItemTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: ITEM_TYPES_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    // Reduce and flat map the item types
    const itemTypes: ItemType[] = propertyFilters.flatMap(
        (f: PropertyFilterSelection): ItemType[] => f.value.split(',') as ItemType[],
    );

    // Return
    return { in: [...new Set(itemTypes)] };
}

export function getTitleFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ItemTitleFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: TITLE_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    return propertyFilters.reduce(
        (accumulator: ItemTitleFilterInput, filter) => {
            if (filter.operator === Operator.Neq) {
                accumulator.neq = filter.value;
            } else if (filter.operator === Operator.Regex) {
                accumulator.regex = filter.value;
            } else if (filter.operator === Operator.Eq) {
                accumulator.eq = filter.value;
            } else if (filter.operator === Operator.Inc) {
                accumulator.inc = filter.value;
            } else if (filter.operator === Operator.Exc) {
                accumulator.exc = filter.value;
            } else {
                // Add the value to the in operator
                const valuesSplit = new Set(filter.value.split(','));
                valuesSplit.forEach((v) => accumulator.in?.push(v));
            }

            // Return
            return accumulator;
        },
        { in: [] },
    );
}

export function getCommentFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ItemCommentFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: COMMENT_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    return propertyFilters.reduce(
        (accumulator: ItemCommentFilterInput, filter) => {
            if (filter.operator === Operator.Neq) {
                accumulator.neq = filter.value;
            } else if (filter.operator === Operator.Regex) {
                accumulator.regex = filter.value;
            } else if (filter.operator === Operator.Eq) {
                accumulator.eq = filter.value;
            } else if (filter.operator === Operator.Inc) {
                accumulator.inc = filter.value;
            } else if (filter.operator === Operator.Exc) {
                accumulator.exc = filter.value;
            } else if (filter.operator === Operator.Empty) {
                accumulator.empty = filter.value === 'true';
            } else {
                // Add the value to the in operator
                const valuesSplit = new Set(filter.value.split(','));
                valuesSplit.forEach((v) => accumulator.in?.push(v));
            }

            // Return
            return accumulator;
        },
        { in: [] },
    );
}

export function getConnectorItemTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ConnectorItemTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: CONNECTOR_ITEM_TYPES_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    return propertyFilters.reduce(
        (accumulator: ConnectorItemTypeFilterInput, filter) => {
            if (filter.operator === Operator.Neq) {
                accumulator.neq = filter.value as ChangeType;
            } else if (filter.operator === Operator.Regex) {
                accumulator.regex = filter.value;
            } else if (filter.operator === Operator.Eq) {
                accumulator.eq = filter.value;
            } else {
                // Add the value to the in operator
                const valuesSplit = new Set(filter.value.split(','));
                valuesSplit.forEach((v) => accumulator.in?.push(v));
            }

            // Return
            return accumulator;
        },
        { in: [] },
    );
}

export function getChangeTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
    datetime: TimelineDateSelection,
): ChangeTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: CHANGE_TYPE_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    return propertyFilters.reduce(
        (accumulator: ChangeTypeFilterInput, filter) => {
            if (filter.operator === Operator.Empty) {
                // Parse the datetime
                let days: number = Number(datetime.customDaysStr);
                if (
                    [DatePreset.OneDay, DatePreset.OneWeek, DatePreset.OneMonth, DatePreset.ThreeMonths].includes(
                        datetime.preset,
                    )
                ) {
                    days = datePresetToDays(
                        datetime.preset as Exclude<
                            DatePreset,
                            DatePreset.Custom | DatePreset.Today | DatePreset.StartOfWeek | DatePreset.EndOfWeek
                        >,
                    );
                }

                // Add the since operator
                accumulator.since = {
                    changeCount: filter.value === 'true' ? { eq: 0 } : { neq: 0 },
                    dateQuery: {
                        type: TimelineDateType.RelativeDaysMinus,
                        relativeDays: days,
                        userIanaZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                };
            } else if (filter.operator === Operator.Neq) {
                accumulator.neq = filter.value as ChangeType;
            } else if (filter.operator === Operator.Eq) {
                accumulator.eq = filter.value as ChangeType;
            } else {
                // Add the value to the in operator
                const valuesSplit = new Set(filter.value.split(',') as ChangeType[]);
                valuesSplit.forEach((v) => accumulator.in?.push(v));
            }

            // Return
            return accumulator;
        },
        { in: [] },
    );
}

function getChangeActionTypesFromPropertyFilters(
    propertyFilterGroup: PropertyFilterGroup,
): ChangeActionTypeFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: CHANGE_ACTION_TYPE_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    // Reduce and flat map the types
    const actionTypes: ChangeActionType[] = propertyFilters.flatMap(
        (f: PropertyFilterSelection): ChangeActionType[] => f.value.split(',') as ChangeActionType[],
    );

    // Return
    return { in: [...new Set(actionTypes)] };
}

function getActorsFromPropertyFilters(propertyFilterGroup: PropertyFilterGroup): ActorFilterInput | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: UPDATED_BY_TYPE_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    // Reduce and flat map the types
    const actorType: (ActorPlaceholder | string)[] = propertyFilters.flatMap(
        (f: PropertyFilterSelection): (ActorPlaceholder | string)[] =>
            f.value.split(',') as (ActorPlaceholder | string)[],
    );

    // Return
    return { in: [...new Set(actorType)] };
}

function getTimelineDateTypeFromValue(
    value: Exclude<
        DatePreset,
        | DatePreset.Custom
        | DatePreset.OneDay
        | DatePreset.OneWeek
        | DatePreset.TwoWeeks
        | DatePreset.OneMonth
        | DatePreset.ThreeMonths
    >,
): TimelineDateType {
    switch (value) {
        case DatePreset.Today:
            return TimelineDateType.Today;
        case DatePreset.StartOfWeek:
            return TimelineDateType.StartOfWeek;
        case DatePreset.EndOfWeek:
            return TimelineDateType.EndOfWeek;
    }
}

function getSanitizedPropertyFilters(propertyFilterGroup: PropertyFilterGroup): {
    [operator: string]:
        | {
              value: string | boolean | PropertyFieldValueInput;
              property: string | undefined;
          }
        | {
              value: string | boolean | PropertyFieldValueInput;
              property: string | undefined;
          }[];
}[] {
    // Filter the property filter group
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, {
        type: 'predefined_properties',
        props: PROPERTY_FILTER_OPTIONS,
    });
    if (propertyFilters.length === 0) {
        return [];
    }

    // Map the filters and return
    return propertyFilters.map((f) => {
        let value: string | boolean | PropertyFieldValueInput = f.value;
        let property: string | undefined = f.property;

        // String or boolean values
        if (ROOT_VALUE_OPERATORS.includes(f.operator)) {
            // Map empty operator value to boolean
            if (f.operator === Operator.Empty) {
                value = f.value === 'true';
            }
        }
        // PropertyFieldValueInput values
        else {
            // End date
            if (f.property === Property.StartDate || f.property === Property.EndDate) {
                if (isTimePeriodOption(f.value)) {
                    value = {
                        dateQuery: {
                            type: getTimelineDateTypeFromValue(
                                f.value as Exclude<
                                    DatePreset,
                                    | DatePreset.Custom
                                    | DatePreset.OneDay
                                    | DatePreset.OneWeek
                                    | DatePreset.TwoWeeks
                                    | DatePreset.OneMonth
                                    | DatePreset.ThreeMonths
                                >,
                            ),
                            userIanaZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                    };
                } else {
                    const relativeDays = Number(f.value);
                    value = {
                        dateQuery: {
                            type:
                                relativeDays < 0
                                    ? TimelineDateType.RelativeDaysMinus
                                    : TimelineDateType.RelativeDaysPlus,
                            relativeDays: Math.abs(relativeDays),
                            userIanaZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                    };
                }
            }
            // Status Category
            else if (f.property === Property.StatusCategory) {
                property = Property.Status;
                value = {
                    statusCategory: f.value as StatusCategory,
                };
            }
            // Other properties
            else {
                // In operator
                if (f.operator === Operator.In) {
                    const values = f.value.split(',').map((v) => ({ value: { rawValue: v }, property: property }));
                    return { [f.operator as Operator]: values };
                }

                // Other operators
                value = { rawValue: f.value };
            }
        }

        // Return
        return { [f.operator as Operator]: { value: value, property: property } };
    });
}

function getCustomNameValueProperties(
    propertyFilterGroup: PropertyFilterGroup,
): Record<string, { value: string | boolean; name: string }>[] | undefined {
    // Filter the filters
    const propertyFilters = filterOptions(propertyFilterGroup.propertyFilters, { type: 'custom_properties' });
    if (propertyFilters.length === 0) {
        return undefined;
    }

    const res: Record<string, { value: string | boolean; name: string }>[] = [];

    // Map the filters and return
    propertyFilters.forEach((f) => {
        let value: string | boolean = f.value;
        let property: string | undefined = f.property;
        // Map empty operator value to boolean
        if (f.operator === Operator.Empty) {
            value = f.value === 'true';
        }

        if (!property || value == null) {
            return;
        }

        res.push({ [f.operator as string]: { value: value, name: property } });
    });

    return res;
}

export function getActivityFilters(
    propertyFiltersGroups: PropertyFilterGroup[],
    filterOperator: ActivityItemFilterOperator,
    graphFilterType: GraphFilterType,
    datetime: TimelineDateSelection,
): ActivityItemFiltersInput {
    // Initialize the filters
    const filters: ActivityItemFiltersInput = {
        filters: [],
        graphFilterType: graphFilterType,
        operator: filterOperator,
    };

    // Iterate over the property filters groups
    filters.filters = propertyFiltersGroups.flatMap((propertyFilterGroup) => {
        // Item types
        const itemTypes = getItemTypesFromPropertyFilters(propertyFilterGroup);

        // Connector item types
        const connectorItemType = getConnectorItemTypesFromPropertyFilters(propertyFilterGroup);

        // Properties
        const properties = getSanitizedPropertyFilters(propertyFilterGroup);

        // Custom name value properties
        const propertiesNameValue = getCustomNameValueProperties(propertyFilterGroup);

        // Change type
        const changeType = getChangeTypesFromPropertyFilters(propertyFilterGroup, datetime);

        // Change action type
        const changeActionType = getChangeActionTypesFromPropertyFilters(propertyFilterGroup);

        // Actors
        const actors = getActorsFromPropertyFilters(propertyFilterGroup);

        // Title
        const title = getTitleFromPropertyFilters(propertyFilterGroup);

        // Comment
        const comment = getCommentFromPropertyFilters(propertyFilterGroup);

        // Check if we should return the filter
        if (
            itemTypes ||
            properties.length > 0 ||
            changeType ||
            changeActionType ||
            actors ||
            propertiesNameValue ||
            connectorItemType ||
            title ||
            comment
        ) {
            const r: ActivityItemFiltersInput['filters'] = [
                {
                    type: itemTypes,
                    properties: properties,
                    changeType: changeType,
                    action: changeActionType,
                    actors,
                    propertiesNameValue,
                    connectorItemType,
                    title,
                    comment,
                },
            ];
            return r;
        }

        // Ignore the filter
        return [];
    });

    // Return the filters
    return filters;
}
