// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Anchor, Box, Spinner, Text } from 'grommet';
import { FormLock, Refresh } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { gql } from '../../../../../__generated__';
import {
    OrgWideConnectorConnectedState,
    SlackChannel,
    SlackChannelMention,
    SlackTeamChannels,
} from '../../../../../__generated__/graphql';
import { DropdownMenuComponent } from '../../../../../components/DropdownMenu';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { useNavNoRerender } from '../../../../../shared/UseNavNoRerender';
import { FontFamily } from '../../../../../theme';

export const GET_SLACK_CHANNELS = gql(/* GraphQL */ `
    query GetSlackChannelsForBot($connectorUserId: String!) {
        slackChannelsForBot(connectorUserId: $connectorUserId) {
            teamId
            teamName
            channels {
                id
                name
                private
            }
        }
    }
`);

function SlackChannelListCached(props: {
    onSelected: (teamId: string, teamName: string, channels: SlackChannel[]) => void;
    slackTeamId: string;
    selectedIds: string[];
}): JSX.Element | null {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    const [selected, setSelected] = useState<
        {
            label: string;
            id: string;
        }[]
    >([]);

    const { loading, data, refetch } = useQuery(GET_SLACK_CHANNELS, {
        nextFetchPolicy: 'no-cache',
        variables: {
            connectorUserId: props.slackTeamId,
        },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get Slack channels` });
            logger.error(error.message);
        },
        onCompleted: (d) => {
            if (!d.slackChannelsForBot) {
                return;
            }
            setSelected(
                d.slackChannelsForBot.channels
                    .filter((channel) => props.selectedIds.includes(channel.id))
                    .map((channel) => ({
                        label: channel.name,
                        id: channel.id,
                    })),
            );
        },
    });

    if (loading) {
        return <Spinner />;
    }

    if (!data || !data.slackChannelsForBot) {
        return null;
    }

    return (
        <Box direction="row" gap="xsmall" align="center">
            <DropdownMenuComponent
                label={
                    selected.length === 0
                        ? `${data.slackChannelsForBot.teamName}: pick a channel`
                        : selected.length === 1
                        ? `${data.slackChannelsForBot.teamName}: ${selected[0].label}`
                        : `${data.slackChannelsForBot.teamName}: ${selected.length} channels selected`
                }
                options={data.slackChannelsForBot.channels.map((ch) => ({
                    id: ch.id,
                    label: ch.name,
                    icon: ch.private ? (
                        <FormLock size="18px" />
                    ) : (
                        <Text size="18px" style={{ marginRight: '2px' }}>
                            #
                        </Text>
                    ),
                }))}
                selected={selected}
                onSelectionChange={(sel) => {
                    if (!data.slackChannelsForBot) {
                        return;
                    }

                    setSelected(sel);

                    const channelIds = sel.map((s) => s.id);
                    props.onSelected(
                        data.slackChannelsForBot.teamId,
                        data.slackChannelsForBot.teamName,
                        data.slackChannelsForBot.channels.filter((channel) => channelIds.includes(channel.id)),
                    );
                }}
                getTextProps={() => ({ color: Colors.dark_3, size: '16px', style: { fontWeight: 'bold' } })}
                boxProps={{
                    pad: '5px 16px',
                    border: { color: Colors.accent_3 },
                    justify: 'between',
                    width: '400px',
                    round: '8px',
                    margin: '0',
                }}
            />
            <IconButtonV2
                icon={(hover, _disabled, submitting) =>
                    submitting ? (
                        <Spinner width="14px" height="14px" />
                    ) : (
                        <Refresh size="16px" color={hover ? Colors.brand : undefined} />
                    )
                }
                onClickWithSpinner={async () => {
                    await refetch();
                }}
                tooltip="Refresh channel list"
            />
        </Box>
    );
}

function getSlackChannelMentionLabel(mention: SlackChannelMention): string {
    switch (mention) {
        case SlackChannelMention.None:
            return 'None';
        case SlackChannelMention.Channel:
            return '@channel';
        case SlackChannelMention.Here:
            return '@here';
    }
}

const CHANNEL_MENTION_OPTIONS = [SlackChannelMention.None, SlackChannelMention.Here, SlackChannelMention.Channel];

export function SlackChannelsCached(props: {
    onSelectionChange: (slackNotificationState: SlackTeamChannels[]) => void;
    hideTitle?: boolean;
    existingNotifications: SlackTeamChannels[];
    onChannelMentionChange: (mention: SlackChannelMention) => void;
    channelMention: SlackChannelMention;
}): JSX.Element | null {
    const { userState } = useUserStateContext();
    const [slackConnectors, setSlackConnectors] = useState<OrgWideConnectorConnectedState[]>([]);
    const navigate = useNavNoRerender();
    const [teamChannels, setTeamChannels] = useState<Map<string, SlackTeamChannels>>(
        new Map(props.existingNotifications.map((n) => [n.teamId, n])),
    );

    useEffect(() => {
        if (!userState) {
            setSlackConnectors([]);
            return;
        }
        setSlackConnectors(userState.connectedOrgWideConnectors.filter((c) => c.connector === ConnectorName.SLACK));
    }, [userState]);

    if (slackConnectors.length === 0) {
        return (
            <Text>
                <Anchor onClick={() => navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.connect.root]))}>
                    Connect
                </Anchor>{' '}
                Slack to receive notifications.
            </Text>
        );
    }

    return (
        <Box gap="xsmall">
            {!props.hideTitle && (
                <Text style={{ fontFamily: FontFamily.Heading }} weight={'bold'}>
                    Slack
                </Text>
            )}
            <Box gap="xxsmall">
                {slackConnectors.map((s) => (
                    <SlackChannelListCached
                        key={s.connectorUserId}
                        slackTeamId={s.connectorUserId}
                        onSelected={(teamId, teamName, channels) => {
                            const newMap = new Map(teamChannels);
                            newMap.set(teamId, {
                                teamId,
                                teamName,
                                channels,
                            });
                            setTeamChannels(newMap);
                            props.onSelectionChange(Array.from(newMap.values()));
                        }}
                        selectedIds={props.existingNotifications.flatMap((slackTeam: SlackTeamChannels) =>
                            slackTeam.channels.map((c) => c.id),
                        )}
                    />
                ))}
            </Box>
            {props.existingNotifications.length > 0 && (
                <DropdownMenuComponent
                    label={`Channel Mention: ${getSlackChannelMentionLabel(props.channelMention)}`}
                    options={CHANNEL_MENTION_OPTIONS.map((mention) => ({
                        id: mention,
                        label: getSlackChannelMentionLabel(mention),
                    }))}
                    selected={[{ id: props.channelMention, label: getSlackChannelMentionLabel(props.channelMention) }]}
                    onSelectionChange={(sel) => {
                        if (sel.length === 0) {
                            return;
                        }
                        props.onChannelMentionChange(sel[0].id as SlackChannelMention);
                    }}
                    singleSelectionOnly
                    hideSearch
                    dropMenuLabel={
                        <Box pad={{ top: 'xsmall', horizontal: 'xsmall' }} direction="row" width={'420px'}>
                            <Text size="14px">
                                Include a Slack channel mention in the automation to ensure people are notified.
                            </Text>
                        </Box>
                    }
                    getTextProps={() => ({ color: Colors.dark_3, size: '16px', style: { fontWeight: 'bold' } })}
                    boxProps={{
                        pad: '5px 16px',
                        border: { color: Colors.accent_3 },
                        justify: 'between',
                        width: '400px',
                        round: '8px',
                        margin: '0',
                    }}
                />
            )}
        </Box>
    );
}
