// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import styled, { keyframes } from 'styled-components';
import TwIcon from './../icons/tw_icon.svg?react';

const TwLoaderContainer = styled.div<{ notAbsolute?: boolean }>`
    width: 100px;
    height: 60px;
    position: ${(props) => (props.notAbsolute ? undefined : 'absolute')};
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const TwLoadingKeyframes = keyframes`
0%{
    left:  -100px;
}
80%{
    left: 0px;
}
100%{
    left: 0px;
}`;

const TwLoader = styled.div`
    width: 200px;
    height: 60px;
    display: block;
    position: absolute;
    left: -100px;
    top: 0;
    animation: ${TwLoadingKeyframes} 1.2s infinite cubic-bezier(0.74, -0.01, 0.27, 1);
`;

const TwLoaderElem = styled(TwIcon)`
    width: 100px;
    height: 60px;
    fill: ${Colors.brand};
`;

export default function Loading(props: { notAbsolute?: boolean }): JSX.Element {
    return (
        <TwLoaderContainer notAbsolute={props.notAbsolute}>
            <TwLoader>
                <TwLoaderElem />
                <TwLoaderElem />
            </TwLoader>
        </TwLoaderContainer>
    );
}
