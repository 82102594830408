// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { gql } from '../__generated__';
import { Environment, getEnvironment } from '../shared/Environment';
import { useTelemetryContext } from './TelemetryContext';

const GET_BUILD_HASH = gql(/* GraphQL */ `
    query GetBuildHash {
        buildHash
    }
`);

/// Checks for updates to the build hash and sets isUpdateAvailable to true if an update is available.
/// This hook will poll the server every 15 minutes to check for updates.
/// If an update is available, the hook will stop polling.
/// The hook will also refetch the build hash when the browser regains focus.
const useCheckForBuildUpdate = (): { isUpdateAvailable: boolean } => {
    const { logger } = useTelemetryContext();
    const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

    const { refetch, stopPolling } = useQuery(GET_BUILD_HASH, {
        skip: getEnvironment() === Environment.Development,
        pollInterval: 15 * 60 * 1000, // 15 mins
        // to ensure onCompleted is called with every poll
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const currentHash = import.meta.env.VITE_COMMIT_HASH;
            if (data.buildHash !== currentHash) {
                setIsUpdateAvailable(true);
                stopPolling();
            }
        },
        onError: (error) => {
            logger.error(error.message);
        },
    });

    useEffect(() => {
        if (getEnvironment() === Environment.Development) {
            return;
        }

        // Call refetch when the browser regains focus
        const onFocus = () => {
            refetch();
        };
        window.addEventListener('focus', onFocus);
        return () => {
            window.removeEventListener('focus', onFocus);
        };
    }, [refetch]);

    return { isUpdateAvailable };
};

export default useCheckForBuildUpdate;
