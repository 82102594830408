// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, Text } from 'grommet';
import { useCallback, useState } from 'react';
import { ConnectorScopeInput, GetHomeScopesQuery } from '../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import { CtaButtonSpinnerV2 } from '../../../../components/CtaButtonSpinner';
import { DropdownMenuComponent } from '../../../../components/DropdownMenu';
import { Thunder } from '../../../../icons/Thunder';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';
import { CreationMode } from '../magic-composer/components/MagicComposerBuilder';

export function CreateAutomationDropdown(props: {
    scopes: GetHomeScopesQuery['homeScopes']['scopes'][0]['scope'][];
}): JSX.Element | null {
    const [selected, setSelected] = useState<{ label: string; id: string }[]>([]);

    const navigate = useNavNoRerender();
    const goToMagicComposer = useCallback(
        (scopes: ConnectorScopeInput[]) => {
            const encoded = encodeURIComponent(JSON.stringify(scopes));
            const path = `${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${
                CreationMode.Automation
            }&${QueryParams.Scopes}=${encoded}`;
            navigate(`${path}`);
        },
        [navigate],
    );

    if (props.scopes.length === 0) {
        return null;
    }

    return (
        <Box
            direction="row"
            align="center"
            gap="xxsmall"
            background={{ color: Colors.accent_3 }}
            round="4px"
            pad={{ horizontal: '8px', vertical: '2px' }}
        >
            <Thunder size="16px" />
            <DropdownMenuComponent
                label="Create"
                beforeChecklistComponent={
                    <Box width={{ min: '300px', width: '300px' }} gap={'xxsmall'} pad={{ bottom: 'xxsmall' }}>
                        <Text size="14px">Select items to create an automation.</Text>
                        <Box direction="row" align="center">
                            <CtaButtonSpinnerV2
                                label="Create Automation"
                                disabled={selected.length === 0}
                                style={{ width: '200px' }}
                                onClick={async () => {
                                    const selectedScopes = props.scopes.filter((s) =>
                                        selected.some((sel) => sel.id === s.id),
                                    );
                                    const connectorScopeInput = selectedScopes.map((s) => {
                                        const c: ConnectorScopeInput = {
                                            connector: s.connector,
                                            hierarchyType: s.hierarchyType,
                                            id: s.id,
                                            itemName: s.itemName,
                                            itemUuid: s.itemUuid,
                                        };
                                        return c;
                                    });
                                    goToMagicComposer(connectorScopeInput);
                                }}
                            />
                        </Box>
                    </Box>
                }
                onSelectionChange={setSelected}
                options={props.scopes.map((c) => ({
                    id: c.id,
                    label: c.itemName,
                    icon: <ConnectorIconSmall sizePixels="12px" name={c.connector as ConnectorName} />,
                }))}
                selected={selected}
                boxProps={{ height: '24px', border: { color: 'unset' }, margin: '0px' }}
                dropProps={{ align: { top: 'bottom', right: 'right' } }}
            />
        </Box>
    );
}
