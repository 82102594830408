// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import * as emailValidator from 'email-validator';
import { Anchor, Box, Drop, Spinner, Tag, Text, TextInput } from 'grommet';
import { Add } from 'grommet-icons';
import { useCallback, useRef, useState } from 'react';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { GET_THIS_USER } from '../../ws/WorkspaceCreate';

function AddEmail(props: {
    onNewEmail: (email: string) => void;
    showUserEmailButton: boolean;
    userEmail?: string;
}): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    const [state, setState] = useState<'idle' | 'adding'>('idle');
    const [email, setEmail] = useState<string>('');
    const onNewEmail = props.onNewEmail;
    const targetRef = useRef<HTMLDivElement>(null);

    const validateEmailAndNotify = useCallback(
        (email: string) => {
            if (!emailValidator.validate(email)) {
                postErrorMessage({ title: 'Invalid Email', shortDesc: 'Please enter a valid email address' });
                return;
            }

            onNewEmail(email);
            setEmail('');
            setState('idle');
        },
        [onNewEmail, postErrorMessage],
    );

    const setStateToIdleAndMaybeNotify = useCallback(
        (email: string) => {
            if (emailValidator.validate(email)) {
                onNewEmail(email);
            }
            setEmail('');
            setState('idle');
        },
        [onNewEmail],
    );

    return (
        <Box direction="row" gap="xxsmall" align="center" ref={targetRef}>
            <IconButtonV2
                icon={(hover) => <Add size="16px" color={hover ? Colors.brand : undefined} />}
                label={(hover) => (
                    <Text size="14px" color={hover ? Colors.brand : undefined}>
                        Add Email Address
                    </Text>
                )}
                reverse
                onClick={() => setState('adding')}
            />
            {state === 'adding' && (
                <Drop
                    target={targetRef.current || undefined}
                    onEsc={() => setStateToIdleAndMaybeNotify(email)}
                    onClickOutside={() => setStateToIdleAndMaybeNotify(email)}
                    plain
                    width={{ min: 'max-content' }}
                >
                    <Box
                        direction="row"
                        align="center"
                        width={{ min: 'max-content' }}
                        background={{ color: Colors.background_back }}
                        pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                        round="8px"
                    >
                        <TextInput
                            autoFocus
                            placeholder="Type an email address and press enter"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    validateEmailAndNotify(email);
                                }
                            }}
                            style={{
                                borderRadius: '0px',
                                backgroundColor: 'unset',
                                borderWidth: '0px',
                                padding: '0px',
                                width: '295px',
                            }}
                        />
                        {props.showUserEmailButton && props.userEmail && (
                            <Box width={{ min: 'max-content' }}>
                                <Anchor onClick={() => validateEmailAndNotify(props.userEmail!)}>or add yours</Anchor>
                            </Box>
                        )}
                    </Box>
                </Drop>
            )}
        </Box>
    );
}

export function EmailList(props: { emails: string[]; onEmailsUpdate: (emails: string[]) => void }): JSX.Element {
    const { logger } = useTelemetryContext();

    const { loading, data } = useQuery(GET_THIS_USER, {
        onError: (error) => {
            logger.error(error.message);
        },
    });

    if (loading) {
        return <Spinner />;
    }

    return (
        <Box gap="xxsmall">
            {props.emails.length > 0 && (
                <Box direction="row" gap="xxsmall" align="center" wrap margin={{ bottom: 'xxsmall' }}>
                    {props.emails.map((email, ei) => (
                        <Tag
                            key={`email-${ei}`}
                            value={email}
                            size="xsmall"
                            border={{ color: Colors.accent_3 }}
                            round="8px"
                            align="center"
                            onRemove={() => {
                                const newEmails = props.emails.filter((e) => e !== email);
                                props.onEmailsUpdate(newEmails);
                            }}
                        />
                    ))}
                </Box>
            )}
            <Box direction="row" gap="xsmall" align="center">
                <AddEmail
                    onNewEmail={(e) => {
                        // dedupe and update
                        const newEmails = Array.from(new Set([...props.emails, e]));
                        props.onEmailsUpdate(newEmails);
                    }}
                    showUserEmailButton={
                        data != null && data.user.email != null && !props.emails.includes(data.user.email)
                    }
                    userEmail={data?.user.email}
                />
            </Box>
        </Box>
    );
}
