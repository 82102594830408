// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { roundToDecimalsIfHasDecimalPlaces } from '@thatworks/shared-frontend/insights';
import { Box, Drop, Text, TextInput } from 'grommet';
import { Close } from 'grommet-icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SimpleBorderTextButton } from '../../../../../components/FilterDropdown';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { History } from '../../../../../icons/History';
import { FontFamily } from '../../../../../theme';
import { DatePreset, TimelineDateSelection } from './timeline-date-selection';

export function TimeSelectionToolbarButton(props: {
    onDateSelection: (selection: TimelineDateSelection) => void;
    selection: TimelineDateSelection;
    disabled?: boolean;
    loading?: boolean;
    // This is a hack to force the component to rerender when the selection changes
    forceRerenderOnPropsChange?: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const [value, setValue] = useState<TimelineDateSelection>(props.selection);
    const ref = useRef<HTMLDivElement>(null);

    const label = useMemo(() => {
        if (value.preset === DatePreset.Custom) {
            const days = roundToDecimalsIfHasDecimalPlaces(Number(value.customDaysStr), 2);
            const daysWord = days === '1' ? 'day' : 'days';
            return `For the past ${days} ${daysWord}`;
        }
        if (value.preset === DatePreset.Today) {
            return 'For today';
        }
        return `For the past ${value.preset}`;
    }, [value.customDaysStr, value.preset]);

    useEffect(() => {
        if (!props.forceRerenderOnPropsChange) {
            return;
        }
        setValue(props.selection);
    }, [props.selection, props.forceRerenderOnPropsChange]);

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={true}
                icon={History}
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                label={label}
                disabled={props.disabled}
                loading={props.loading}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', left: 'left' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        props.onDateSelection(value);
                        setButtonActive(false);
                    }}
                >
                    <Box
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        round={'10px'}
                        elevation="xsmall"
                        width="400px"
                        pad="xsmall"
                    >
                        <IconButtonV2
                            icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                            reverse
                            onClick={() => {
                                props.onDateSelection(value);
                                setButtonActive(false);
                            }}
                            alignSelf="end"
                        />
                        <Box gap="xsmall" justify="center" align="center">
                            <Text size="16px" style={{ fontFamily: FontFamily.Mono }}>
                                Choose a time period
                            </Text>
                            <Box align="center" direction="row" wrap gap="xsmall" justify="center">
                                {[
                                    DatePreset.Today,
                                    DatePreset.OneDay,
                                    DatePreset.OneWeek,
                                    DatePreset.TwoWeeks,
                                    DatePreset.OneMonth,
                                    DatePreset.ThreeMonths,
                                ].map((p, pi) => (
                                    <SimpleBorderTextButton
                                        onClick={() => {
                                            setValue({ preset: p, customDaysStr: '' });
                                        }}
                                        key={`date-preset-${pi}`}
                                        render={(hover) => (
                                            <Text size="14px" color={hover ? Colors.brand : Colors.dark_4}>
                                                {p}
                                            </Text>
                                        )}
                                        border={{ color: Colors.accent_3, size: '1px' }}
                                        margin={{ bottom: 'xxsmall' }}
                                        boxProps={{
                                            background:
                                                value.preset === p ? Colors.background_back : Colors.background_front,
                                            elevation: 'xsmall',
                                        }}
                                    />
                                ))}
                            </Box>
                            <Box align="center" gap="xsmall">
                                <Text size="16px" style={{ fontFamily: FontFamily.Mono }}>
                                    Or define a custom value
                                </Text>
                                <Box align="center" gap="xxsmall" direction="row">
                                    <TextInput
                                        type="numeric"
                                        pattern="[1-9]+"
                                        placeholder="number"
                                        value={value.customDaysStr}
                                        onChange={(event) => {
                                            if (event.target.value) {
                                                const num = Number(event.target.value);
                                                if (isNaN(num) || !isFinite(num) || num < 1 || num > 9999) {
                                                    return;
                                                }
                                            }
                                            const customDaysStr = event.target.value;
                                            setValue({ preset: DatePreset.Custom, customDaysStr });
                                        }}
                                        style={{
                                            fontFamily: FontFamily.Mono,
                                            borderColor: Colors.accent_3,
                                            padding: '2px 6px',
                                            color: Colors.dark_4,
                                            width: '70px',
                                            fontSize: '14px',
                                            height: '26px',
                                            background:
                                                value.customDaysStr !== ''
                                                    ? Colors.background_back
                                                    : Colors.background_front,
                                        }}
                                        width={{ min: '90px' }}
                                    />
                                    <Text size="16px" style={{ fontFamily: FontFamily.Mono }}>
                                        days
                                    </Text>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
