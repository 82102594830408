// Copyright 2021
// ThatWorks.xyz Limited

import Av0NoCaret from './../icons/fun_avatar_1.svg?react';
import Av1NoCaret from './../icons/fun_avatar_2.svg?react';
import Av2NoCaret from './../icons/fun_avatar_3.svg?react';
import Av3NoCaret from './../icons/fun_avatar_4.svg?react';
import Av0 from './../icons/fun_avatar_caret_1.svg?react';
import Av1 from './../icons/fun_avatar_caret_2.svg?react';
import Av2 from './../icons/fun_avatar_caret_3.svg?react';
import Av3 from './../icons/fun_avatar_caret_4.svg?react';

const listWithCaret = [Av0, Av1, Av2, Av3];
const listWithoutCaret = [Av0NoCaret, Av1NoCaret, Av2NoCaret, Av3NoCaret];
const randomIndex = Math.round(Math.random() * (Math.min(listWithCaret.length, listWithoutCaret.length) - 1));

export function RandomAvatar(props: { size: string; withoutCaret?: boolean }) {
    function Render(props: { im: React.FC<React.SVGProps<SVGSVGElement>>; size: string }): JSX.Element {
        return <props.im width={props.size} height={props.size} style={{ pointerEvents: 'none' }} />;
    }
    return Render({
        im: props.withoutCaret ? listWithoutCaret[randomIndex] : listWithCaret[randomIndex],
        size: props.size,
    });
}
