// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Drop, Text, TextArea } from 'grommet';
import { Close, Edit } from 'grommet-icons';
import { useRef, useState } from 'react';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { FontFamily } from '../../../../../theme';

export function FormatSummaryButton(props: {
    customPrompt: string | undefined;
    loading: boolean;
    onPromptTextUpdate: (p: string) => void;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [customFormattingPrompt, setCustomFormattingPrompt] = useState<string>(props.customPrompt || '');

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={customFormattingPrompt.trim().length > 0}
                icon={Edit}
                label="Rewrite Summary"
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                disabled={props.loading}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', right: 'right' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                    }}
                >
                    <Box
                        width={{ width: '505px', max: '700px' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'10px'}
                        elevation="xsmall"
                        gap="xsmall"
                    >
                        <Box direction="row" justify="between" fill="horizontal" height={{ min: 'max-content' }}>
                            <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                Rewrite Summary
                            </Text>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={() => setButtonActive(false)}
                                alignSelf="end"
                            />
                        </Box>
                        <Box gap="xxsmall">
                            <Text size="14px">Rewrite the entire summary using a custom prompt.</Text>
                            <TextArea
                                maxLength={500}
                                disabled={props.loading}
                                value={customFormattingPrompt}
                                style={{
                                    minHeight: '100px',
                                    borderColor: Colors.light_5,
                                    backgroundColor: Colors.background_back,
                                }}
                                onChange={(event) => {
                                    setCustomFormattingPrompt(event.target.value);
                                }}
                                placeholder={`Examples:\nGroup by bugs and features\nRewrite into a short paragraph`}
                            />
                        </Box>
                        <Box direction="row" height={{ min: 'max-content' }}>
                            <CtaButtonSpinnerV2
                                disabled={props.loading || customFormattingPrompt === props.customPrompt}
                                style={{ width: '100px' }}
                                label="Apply"
                                onClick={async () => {
                                    props.onPromptTextUpdate(customFormattingPrompt);
                                }}
                                showSpinner={props.loading}
                            />
                        </Box>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
