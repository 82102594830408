// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Drop } from 'grommet';
import { useRef, useState } from 'react';
import { ConnectedAppsOption, ConnectorScopeInput } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { GitFork } from '../../../../../icons/GitFork';
import { SearchItems } from './SearchItems';

export function SearchItemsToolbarButton(props: {
    scopes?: ConnectorScopeInput[];
    onScopesChange: (selectedScopes: ConnectorScopeInput[]) => void;
    appsConnectedOptions: ConnectedAppsOption[];
    appsConnectedOptionsLoading: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [selectedResults, setSelectedSearchResults] = useState<Map<string, ConnectorScopeInput>>(() => {
        if (props.scopes) {
            const results: Map<string, ConnectorScopeInput> = new Map();
            props.scopes.forEach((scope) => {
                results.set(scope.id, scope);
            });
            return results;
        }
        return new Map();
    });

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={buttonActive || selectedResults.size > 0}
                icon={GitFork}
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                label={
                    selectedResults.size > 0
                        ? Array.from(selectedResults.values())
                              .map((v) => v.itemName)
                              .join(', ')
                        : 'Choose data sources'
                }
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', left: 'left' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                        props.onScopesChange(Array.from(selectedResults.values()));
                    }}
                >
                    <Box
                        height={{ min: 'max-content', max: '70vh' }}
                        width={{ min: '600px', width: '600px', max: '50vw' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad="20px"
                        round="25px"
                        elevation="xsmall"
                        justify="between"
                        gap="xsmall"
                    >
                        <SearchItems
                            onSelected={(selected) => {
                                setSelectedSearchResults(new Map(selected));
                            }}
                            selected={selectedResults}
                            onClose={() => {
                                setButtonActive(false);
                                props.onScopesChange(Array.from(selectedResults.values()));
                            }}
                            appsConnectedOptions={props.appsConnectedOptions}
                            appsConnectedOptionsLoading={props.appsConnectedOptionsLoading}
                        />
                        <Box height={{ min: 'max-content' }} direction="row" align="center" gap="xxsmall">
                            <CtaButtonSpinnerV2
                                label="Done"
                                style={{ width: '120px' }}
                                onClick={async () => {
                                    setButtonActive(false);
                                    props.onScopesChange(Array.from(selectedResults.values()));
                                }}
                            />
                            <CtaButtonSpinnerV2
                                label="Clear All"
                                style={{ width: '120px' }}
                                notPrimary
                                onClick={async () => {
                                    setSelectedSearchResults(new Map());
                                    props.onScopesChange([]);
                                }}
                            />
                        </Box>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
