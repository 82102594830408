// Copyright 2021
// ThatWorks.xyz Limited

import { gql } from '../../../../__generated__';

export const GET_ORGS = gql(/* GraphQL */ `
    query GetOrgs {
        usersOrganizations {
            id
            displayName
        }
    }
`);
