// Copyright 2021
// ThatWorks.xyz Limited

import {
    ActivityItemFilterOutput,
    ActivityItemPropertyType,
    ActorFilterOutput,
    ChangeActionTypeFilterOutput,
    ChangeTypeFilterOutput,
    ConnectorItemTypeFilterOutput,
    ItemCommentFilterOutput,
    ItemPropertyFilterOutput,
    ItemTitleFilterOutput,
    ItemTypeFilterOutput,
    PropertyFieldFilterEmptyOutput,
    PropertyFieldFilterOutput,
    PropertyFieldFilterStringOutput,
    TimelineDateType,
} from '../../../../../../__generated__/graphql';
import { Operator, Property, PropertyFilterGroup, PropertyFilterSelection } from '../filter-toolbar-button/helpers';
import { timelineDateTypeToDatePreset } from '../timeline-date-selection';

export function getPropertyFiltersFromItemTypes(
    itemTypes: ItemTypeFilterOutput | null | undefined,
): PropertyFilterSelection | undefined {
    // Validate that we have types present
    if (!itemTypes?.in) {
        return undefined;
    }

    // Return
    return {
        property: Property.TypeOfObject,
        operator: Operator.In,
        value: itemTypes.in.join(),
    };
}

export function getPropertyFiltersFromConnectorItemTypes(
    connectorItemTypes: ConnectorItemTypeFilterOutput | null | undefined,
): PropertyFilterSelection[] | undefined {
    // Validate that we have types present
    if (!connectorItemTypes?.in && !connectorItemTypes?.neq && !connectorItemTypes?.eq && !connectorItemTypes?.regex) {
        return undefined;
    }

    const selections: PropertyFilterSelection[] = [];
    if (connectorItemTypes.in) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.In,
            value: connectorItemTypes.in.join(),
        });
    }

    if (connectorItemTypes.eq) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.Eq,
            value: connectorItemTypes.eq,
        });
    }

    if (connectorItemTypes.regex) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.Regex,
            value: connectorItemTypes.regex,
        });
    }

    if (connectorItemTypes.neq) {
        selections.push({
            property: Property.TypeOfItem,
            operator: Operator.Neq,
            value: connectorItemTypes.neq,
        });
    }

    // Return
    return selections;
}

export function getPropertyFiltersFromChangeType(
    changeType: ChangeTypeFilterOutput | null | undefined,
): PropertyFilterSelection[] | undefined {
    // Validate that this field is filled
    const since = changeType?.since;
    const inList = changeType?.in || [];
    const neq = changeType?.neq;
    const eq = changeType?.eq;
    if (since == null && inList.length === 0 && neq == null && eq == null) {
        return undefined;
    }

    // Initialise
    const selections: PropertyFilterSelection[] = [];

    // Since value
    if (since) {
        selections.push({
            property: Property.Changes,
            operator: Operator.Empty,
            value: since.changeCount?.eq === 0 ? 'true' : 'false',
        });
    }

    // In
    if (inList && inList.length > 0) {
        selections.push({
            property: Property.Changes,
            operator: Operator.In,
            value: inList.join(),
        });
    }

    // Eq
    if (eq) {
        selections.push({
            property: Property.Changes,
            operator: Operator.Eq,
            value: eq,
        });
    }

    // Neq
    if (neq) {
        selections.push({
            property: Property.Changes,
            operator: Operator.Neq,
            value: neq,
        });
    }

    // Return
    return selections;
}

function getPropertyFiltersFromChangeActionTypes(
    changeActionType: ChangeActionTypeFilterOutput | null | undefined,
): PropertyFilterSelection | undefined {
    // Validate if the change action type is filled
    if (changeActionType == null || changeActionType.in == null || changeActionType.in.length === 0) {
        return undefined;
    }

    // Return
    return {
        property: Property.Action,
        operator: Operator.In,
        value: changeActionType.in.join(),
    };
}

export function getPropertyFiltersFromProperties(
    properties: ItemPropertyFilterOutput[] | null | undefined,
): PropertyFilterSelection[] | undefined {
    // Validate that we have properties present
    if (!properties || properties.length === 0) {
        return undefined;
    }

    // Return
    return properties.map((property) => {
        // Initialize selection
        const selection: PropertyFilterSelection = {
            property: undefined,
            operator: undefined,
            value: '',
        };

        // Iterate over all the operators in the property
        for (let key in property) {
            let propertyField:
                | PropertyFieldFilterEmptyOutput
                | PropertyFieldFilterOutput
                | PropertyFieldFilterStringOutput;

            // Continue to the next key if the property field is empty
            if (property[key as Operator] == null) {
                continue;
            }

            if (key === Operator.In) {
                // Validate the property fields
                const propertyFields = property[key as Operator] as PropertyFieldFilterOutput[];
                const propertiesSet = new Set(propertyFields.map((p) => p.property));
                const valuesSet = new Set(
                    propertyFields.flatMap((p) => (p.value.rawValue != null ? [p.value.rawValue] : [])),
                );

                // If we have more than one property or if we don't have values, continue to the next key
                if (propertiesSet.size !== 1 || valuesSet.size === 0) {
                    continue;
                }

                // Get property
                const propertyIn = propertiesSet.values().next().value as ActivityItemPropertyType;

                // Get value
                const valueIn = { rawValue: [...valuesSet].join(',') };

                propertyField = { property: propertyIn, value: valueIn };
            } else {
                // Get the property field
                propertyField = property[key as Operator] as
                    | PropertyFieldFilterEmptyOutput
                    | PropertyFieldFilterOutput
                    | PropertyFieldFilterStringOutput;
            }

            // Validate if the filters support the property received
            const propertyOptions = Object.values(Property) as string[];
            if (!propertyOptions.includes(propertyField.property)) {
                continue;
            }

            // Update operator
            selection.operator = key as Operator;

            // Update property
            selection.property = propertyField.property as string as Property;

            // Update empty operator value
            if (typeof propertyField.value === 'boolean') {
                selection.value = propertyField.value.toString();
            }
            // Update regex operator value
            else if (typeof propertyField.value === 'string') {
                selection.value = propertyField.value;
            }
            // Update PropertyFieldValueOutput value
            else {
                const dateQuery = propertyField.value.dateQuery;
                const statusCategory = propertyField.value.statusCategory;

                // End date
                if (
                    (selection.property === Property.EndDate || selection.property === Property.StartDate) &&
                    dateQuery
                ) {
                    // Relative days
                    if (dateQuery.relativeDays != null) {
                        selection.value = `${dateQuery.relativeDays}`;
                        if (dateQuery.type === TimelineDateType.RelativeDaysMinus) {
                            selection.value = `-${selection.value}`;
                        }
                    }
                    // Date preset option
                    else {
                        selection.value = timelineDateTypeToDatePreset(dateQuery.type);
                    }
                }
                // Status category
                else if (selection.property === Property.Status && statusCategory) {
                    selection.property = Property.StatusCategory;
                    selection.value = statusCategory;
                }
                // Other properties with regex values
                else {
                    selection.value = propertyField.value.rawValue ?? '';
                }
            }

            // If we are here, we have updated the selection and we can exit the loop
            break;
        }

        // Return
        return selection;
    });
}

function getPropertyFiltersFromActors(
    actors: ActorFilterOutput | null | undefined,
): PropertyFilterSelection | undefined {
    // Validate that we have actors present
    if (actors == null || actors.in == null || actors.in.length === 0) {
        return undefined;
    }

    // Return
    return {
        property: Property.UpdatesBy,
        operator: Operator.In,
        value: actors.in.join(),
    };
}

function getPropertyFiltersFromTitle(
    title: ItemTitleFilterOutput | null | undefined,
): PropertyFilterSelection[] | undefined {
    const selections: PropertyFilterSelection[] = [];

    // Validate that we have title filters present
    if (
        (!title?.in || title.in.length === 0) &&
        !title?.neq &&
        !title?.eq &&
        !title?.regex &&
        !title?.inc &&
        !title?.exc
    ) {
        return undefined;
    }

    // In
    if (title.in) {
        selections.push({
            property: Property.Title,
            operator: Operator.In,
            value: title.in.join(),
        });
    }

    // Eq
    if (title.eq) {
        selections.push({
            property: Property.Title,
            operator: Operator.Eq,
            value: title.eq,
        });
    }

    // Neq
    if (title.neq) {
        selections.push({
            property: Property.Title,
            operator: Operator.Neq,
            value: title.neq,
        });
    }

    // Regex
    if (title.regex) {
        selections.push({
            property: Property.Title,
            operator: Operator.Regex,
            value: title.regex,
        });
    }

    // Inc
    if (title.inc) {
        selections.push({
            property: Property.Title,
            operator: Operator.Inc,
            value: title.inc,
        });
    }

    // Exc
    if (title.exc) {
        selections.push({
            property: Property.Title,
            operator: Operator.Exc,
            value: title.exc,
        });
    }

    // Return the title filters selected
    return selections;
}

function getPropertyFiltersFromComment(
    comment: ItemCommentFilterOutput | null | undefined,
): PropertyFilterSelection[] | undefined {
    const selections: PropertyFilterSelection[] = [];

    // Validate that we have comment filters present
    if (
        (!comment?.in || comment.in.length === 0) &&
        !comment?.neq &&
        !comment?.eq &&
        !comment?.regex &&
        !comment?.inc &&
        !comment?.exc &&
        !comment?.empty
    ) {
        return undefined;
    }

    // In
    if (comment.in) {
        selections.push({
            property: Property.Comment,
            operator: Operator.In,
            value: comment.in.join(),
        });
    }

    // Eq
    if (comment.eq) {
        selections.push({
            property: Property.Comment,
            operator: Operator.Eq,
            value: comment.eq,
        });
    }

    // Neq
    if (comment.neq) {
        selections.push({
            property: Property.Comment,
            operator: Operator.Neq,
            value: comment.neq,
        });
    }

    // Regex
    if (comment.regex) {
        selections.push({
            property: Property.Comment,
            operator: Operator.Regex,
            value: comment.regex,
        });
    }

    // Inc
    if (comment.inc) {
        selections.push({
            property: Property.Comment,
            operator: Operator.Inc,
            value: comment.inc,
        });
    }

    // Exc
    if (comment.exc) {
        selections.push({
            property: Property.Comment,
            operator: Operator.Exc,
            value: comment.exc,
        });
    }

    // Empty
    if (comment.empty) {
        selections.push({
            property: Property.Comment,
            operator: Operator.Exc,
            value: comment.empty ? 'true' : 'false',
        });
    }

    // Return the title filters selected
    return selections;
}

export function getPropertyFilters(filters: ActivityItemFilterOutput[]): PropertyFilterGroup[] {
    // Initialize the property filters groups
    const propertyFiltersGroups: PropertyFilterGroup[] = [];

    // Iterate over the filters
    filters.forEach((filter) => {
        // Initialize property filters
        const propertyFilters: PropertyFilterSelection[] = [];

        // Item types
        const typeFilter = getPropertyFiltersFromItemTypes(filter.type);
        if (typeFilter) {
            propertyFilters.push(typeFilter);
        }

        // Connector item types
        const connectorItemType = getPropertyFiltersFromConnectorItemTypes(filter.connectorItemType);
        if (connectorItemType && connectorItemType.length > 0) {
            propertyFilters.push(...connectorItemType);
        }

        // Properties
        const propertyFilter = getPropertyFiltersFromProperties(filter.properties);
        if (propertyFilter && propertyFilter.length > 0) {
            propertyFilters.push(...propertyFilter);
        }

        // Change type
        const changeType = getPropertyFiltersFromChangeType(filter.changeType);
        if (changeType && changeType.length > 0) {
            propertyFilters.push(...changeType);
        }

        // Change action type
        const changeActionType = getPropertyFiltersFromChangeActionTypes(filter.action);
        if (changeActionType) {
            propertyFilters.push(changeActionType);
        }

        // Actors
        const actors = getPropertyFiltersFromActors(filter.actors);
        if (actors) {
            propertyFilters.push(actors);
        }

        // Title
        const title = getPropertyFiltersFromTitle(filter.title);
        if (title) {
            propertyFilters.push(...title);
        }

        // Comment
        const comment = getPropertyFiltersFromComment(filter.comment);
        if (comment) {
            propertyFilters.push(...comment);
        }

        // Add the property filters to the property filters groups
        propertyFiltersGroups.push({ propertyFilters: propertyFilters });
    });

    // Return the property filters groups
    return propertyFiltersGroups;
}
