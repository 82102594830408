// Copyright 2021
// ThatWorks.xyz Limited

import { Notification, StatusType } from 'grommet';
import { useState } from 'react';
import styled from 'styled-components';
import { getGrommetTheme } from '../theme';

const theme = getGrommetTheme();

export interface ErrorMessage {
    title: string;
    shortDesc: string;
}

export const ServerCannotConnectMessage: ErrorMessage = {
    title: 'Server Error',
    shortDesc: 'Unable to connect.',
};

const StyledHref = styled.a`
    ${theme.anchor?.extend}
    color: ${theme.global?.colors?.black};
    border-bottom-color: ${theme.global?.colors?.black};
    text-decoration: none;
    &:hover {
        ${theme.anchor?.hover?.extend}
    }
`;

function StyledHrefComp(props: { label: string; href?: string; onClick?: () => Promise<void> }) {
    const [loading, setLoading] = useState(false);

    return (
        <StyledHref
            href={loading ? undefined : props.href}
            onClick={async () => {
                if (props.onClick) {
                    setLoading(true);
                    await props.onClick();
                }
            }}
            style={{ cursor: loading ? undefined : 'pointer' }}
        >
            <strong>{loading ? `Loading..` : props.label}</strong>
        </StyledHref>
    );
}

export function CriticalPageErrorToast(props: {
    error: ErrorMessage;
    customAction?: { label: string; href?: string; onClick?: () => Promise<void> };
}): JSX.Element {
    return (
        <Notification
            title={props.error.title}
            message={props.error.shortDesc}
            actions={
                props.customAction
                    ? [
                          {
                              label: props.customAction.label,
                              as: StyledHrefComp,
                              href: props.customAction.href,
                              onClick: props.customAction.onClick,
                          },
                      ]
                    : [{ label: 'Get help.', as: StyledHrefComp, href: import.meta.env.VITE_SUPPORT_HREF }]
            }
            status="critical"
            toast={{ autoClose: false, position: 'bottom-right' }}
        />
    );
}

export function GenericErrorToast(props: {
    error?: ErrorMessage;
    onClose: () => void;
    status?: StatusType;
}): JSX.Element {
    return (
        <>
            {props.error && (
                <Notification
                    title={props.error.title}
                    message={props.error.shortDesc}
                    status={props.status || 'critical'}
                    toast={{ autoClose: true, position: 'bottom-right' }}
                    onClose={props.onClose}
                />
            )}
        </>
    );
}
