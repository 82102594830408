// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box } from 'grommet';
import { View } from 'grommet-icons';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import { DropdownMenuComponent } from '../../../../components/DropdownMenu';

export function ScopeFilterDropdown(props: {
    scopes: { name: string; id: string; connector: ConnectorName }[];
    selected: { label: string; id: string }[];
    onSelectionChange: (sel: { label: string; id: string }[]) => void;
}): JSX.Element | null {
    if (props.scopes.length === 0) {
        return null;
    }

    return (
        <Box direction="row" align="center" gap="xxsmall">
            <View size="16px" color={props.selected.length > 0 ? Colors.accent_3 : undefined} />
            <DropdownMenuComponent
                label={
                    props.selected.length === 0
                        ? 'No filter'
                        : props.selected.length === 1
                        ? '1 filter'
                        : `${props.selected.length} filters`
                }
                onSelectionChange={props.onSelectionChange}
                options={props.scopes.map((c) => ({
                    id: c.id,
                    label: c.name,
                    icon: <ConnectorIconSmall sizePixels="12px" name={c.connector} />,
                }))}
                selected={props.selected}
                boxProps={{ height: '24px', border: { color: 'unset' }, margin: '0px' }}
                getTextProps={(hover) => ({
                    color: hover || props.selected.length > 0 ? Colors.accent_3 : undefined,
                })}
                getIconProps={(hover) => ({
                    color: hover || props.selected.length > 0 ? Colors.accent_3 : undefined,
                })}
            />
        </Box>
    );
}
