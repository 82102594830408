// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { GridAreaType } from 'grommet/utils';
import { useEffect, useMemo, useState } from 'react';
import { gql } from '../../../../../__generated__';
import {
    ItemGroupInput,
    ItemSubgroupInput,
    SummarySectionInput,
    TimelineActivityInput,
    TimelineActivityQuery,
} from '../../../../../__generated__/graphql';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { TwHeading } from '../../../../../components/TwHeading';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';
import { TemplatePreview } from '../../templates/components/TemplatePreview';
import { FormatSummaryButton } from './FormatSummaryButton';
import { BlockType, QueryTemplateBlockSummary, TemplateBlockState, TextTemplateBlockState } from './TemplateBlock';

const TIMELINE_CUSTOM_FORMATTING = gql(/* GraphQL */ `
    mutation TimelineActivitiesCustomFormatting($formattingPrompt: String!, $activity: [TimelineActivityInput!]!) {
        timelineActivitiesCustomFormatting(formattingPrompt: $formattingPrompt, activity: $activity) {
            title
            groups {
                type
                subgroups {
                    ids
                    name
                    props {
                        ... on ItemSubgroupTaskStatus {
                            isDoneStatus
                            sortOrder
                            status
                        }
                    }
                    summary {
                        summary {
                            sections {
                                markdown
                                pills {
                                    value
                                    color
                                    iconUrl
                                    itemUuids
                                    connector
                                }
                                newRowForPills
                            }
                        }
                    }
                }
            }
            items {
                title
                id
                iconUrl
                connector
                url
                actors {
                    names {
                        color
                        isUser
                        name
                    }
                    hasMore
                }
                parents {
                    readableConnectorObjectType
                    name
                    url
                }
                properties {
                    color
                    name
                    value
                    iconUrl
                    valueType
                    propertyType
                }
                changeDescription {
                    color
                    decoration
                    value
                }
                timeRange {
                    newest
                    oldest
                }
                docDiff {
                    ... on DocDiffSummarized {
                        summary
                    }
                }
                comments {
                    comments {
                        authorDisplayName
                        comment
                        date
                        userMention
                    }
                    more
                    total
                }
            }
        }
    }
`);

function toTimelineActivityInput(
    activityBlocks: Array<TimelineActivityQuery['timelineActivity'] & { id: string }>,
): TimelineActivityInput[] {
    return activityBlocks.map((a) => {
        return {
            id: a.id,
            title: a.title,
            groups: a.groups.map((g) => {
                const gg: ItemGroupInput = {
                    subgroups: g.subgroups.map((sg) => {
                        const sub: ItemSubgroupInput = {
                            name: sg.name,
                            summary: sg.summary
                                ? {
                                      summary: {
                                          sections: sg.summary.summary.sections.map((sec) => {
                                              return sec.map((ss) => {
                                                  const sss: SummarySectionInput = {
                                                      markdown: ss.markdown,
                                                  };
                                                  return sss;
                                              });
                                          }),
                                      },
                                  }
                                : undefined,
                        };
                        return sub;
                    }),
                    type: g.type,
                };
                return gg;
            }),
        };
    });
}

type QueryTemplateBlockSummaryData = QueryTemplateBlockSummary | TextTemplateBlockState;
type QueryTemplateBlockSummaryDataWithIndex = QueryTemplateBlockSummaryData & { index: number };

export function MagicComposerPreview(props: {
    gridArea: GridAreaType;
    templateName: string;
    blocks: TemplateBlockState[];
    loadingPreview: boolean;
    summaryCustomFormattingPrompt: string | undefined;
    onSummaryCustomFormattingPromptUpdate: (p: string) => void;
    onPreviewDocChange: (doc: string) => void;
}): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const [blocks, setBlocks] = useState<Array<QueryTemplateBlockSummaryData>>([]);

    const [timelineCustomFormatting, { loading: customFormattingLoading }] = useMutation(TIMELINE_CUSTOM_FORMATTING, {
        onError: (error) => {
            postErrorMessage({
                title: `Error`,
                shortDesc: `Failed to apply custom formatting`,
            });
            logger.error(error.message);
        },
    });

    useEffect(() => {
        const formattingPrompt = props.summaryCustomFormattingPrompt || '';
        if (formattingPrompt.trim().length > 0) {
            // Extract activity blocks and non-activity blocks. Keep track of the index of each block so we can recombine them later.
            const activityBlocks: Array<TimelineActivityQuery['timelineActivity'] & { id: string; index: number }> = [];
            const otherBlocks: Array<QueryTemplateBlockSummaryDataWithIndex> = [];
            props.blocks.forEach((b, bi) => {
                if (b.type === BlockType.Query && b.summary?.type === 'activity') {
                    activityBlocks.push({ ...b.summary.activity, id: b.timelineId || '', title: b.title, index: bi });
                } else {
                    otherBlocks.push({ ...b, index: bi });
                }
            });

            // If there are no activity blocks, we can just set the blocks and return
            if (activityBlocks.length === 0) {
                setBlocks(props.blocks);
                return;
            }

            timelineCustomFormatting({
                variables: {
                    formattingPrompt: formattingPrompt,
                    activity: toTimelineActivityInput(activityBlocks),
                },
                onCompleted: (data) => {
                    // Create the combined activity block
                    const combinedActivityBlock: QueryTemplateBlockSummaryDataWithIndex = {
                        type: BlockType.Query,
                        id: `combined-activity`,
                        selectedInsightIds: [],
                        title: data.timelineActivitiesCustomFormatting.title || '',
                        summary: {
                            type: 'activity',
                            activity: data.timelineActivitiesCustomFormatting,
                        },
                        index: activityBlocks[0].index,
                    };

                    // Combine the pills and items from all the activity blocks into the combined activity block
                    if (
                        combinedActivityBlock.summary?.type === 'activity' &&
                        combinedActivityBlock.summary.activity.groups.length > 0 &&
                        combinedActivityBlock.summary.activity.groups[0].subgroups.length > 0 &&
                        combinedActivityBlock.summary.activity.groups[0].subgroups[0].summary &&
                        combinedActivityBlock.summary.activity.groups[0].subgroups[0].summary.summary.sections.length >
                            0
                    ) {
                        const pills =
                            combinedActivityBlock.summary.activity.groups[0].subgroups[0].summary.summary.sections[0][0]
                                .pills || [];
                        const items = combinedActivityBlock.summary.activity.items || [];
                        activityBlocks.forEach((ab) => {
                            ab.items.forEach((i) => {
                                items.push(i);
                            });
                            ab.groups.forEach((g) => {
                                g.subgroups.forEach((sg) => {
                                    sg.summary?.summary.sections.forEach((sec) => {
                                        sec.forEach((s) => {
                                            if (s.pills) {
                                                pills.push(...s.pills);
                                            }
                                        });
                                    });
                                });
                            });
                        });
                    }

                    // Combine the activity block with the other blocks and sort them by index so that we follow the original order
                    // even though all the activity blocks are now combined into a single block
                    const sortedBlocks = otherBlocks.concat(combinedActivityBlock).sort((a, b) => a.index - b.index);
                    setBlocks(sortedBlocks);
                },
            });
        } else {
            setBlocks(props.blocks);
        }
    }, [props.summaryCustomFormattingPrompt, props.blocks, timelineCustomFormatting]);

    const hasContent = useMemo(
        () =>
            blocks.some(
                (b) =>
                    (b.type === BlockType.Query && b.summary != null) ||
                    (b.type === BlockType.Text && b.text != null && b.text.trim().length > 0),
            ),
        [blocks],
    );

    return (
        <Box gridArea={props.gridArea} background={Colors.background_back}>
            {/* Title */}
            <Box
                height={{ min: 'min-content' }}
                gap="xsmall"
                border={{ color: Colors.border_light, side: 'bottom' }}
                margin={{ bottom: 'xsmall' }}
            >
                <Box pad="xsmall" gap="xsmall">
                    <Text
                        alignSelf="start"
                        size="28px"
                        color={Colors.dark_2}
                        style={{ fontFamily: FontFamily.Callout }}
                    >
                        Summary Preview
                    </Text>
                    <Box direction="row" align="center">
                        <FormatSummaryButton
                            customPrompt={props.summaryCustomFormattingPrompt}
                            loading={props.loadingPreview || customFormattingLoading}
                            onPromptTextUpdate={props.onSummaryCustomFormattingPromptUpdate}
                        />
                    </Box>
                </Box>
            </Box>

            {/* No summaries or loading */}
            {(!hasContent || props.loadingPreview || customFormattingLoading) && (
                <Box alignSelf="center" flex justify="center" align="center" width={'80%'} margin={{ bottom: '60px' }}>
                    {/* No summaries */}
                    {!hasContent && !props.loadingPreview && !customFormattingLoading && (
                        <Text
                            color={Colors.neutral_4}
                            size="28px"
                            textAlign="center"
                            style={{ fontFamily: FontFamily.Heading }}
                        >
                            Compose it and see the magic
                        </Text>
                    )}
                    {/* Loading */}
                    {(props.loadingPreview || customFormattingLoading) && <Spinner size="medium" />}
                </Box>
            )}
            {hasContent && !props.loadingPreview && !customFormattingLoading && (
                <Box gap="small" overflow={{ vertical: 'auto' }} pad={{ horizontal: 'xsmall' }}>
                    <TwHeading level={3}>{props.templateName}</TwHeading>
                    <TemplatePreview blocks={blocks} editable={false} onChange={props.onPreviewDocChange} />
                </Box>
            )}
        </Box>
    );
}
