// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { InlineInsightPillNodeDataBase } from '@thatworks/shared-frontend/prosemirror';
import {
    InlineInsightPillAttributes,
    InlineInsightPillNode,
    MAX_INLINE_INSIGHT_PILLS_TO_SHOW,
} from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box, Drop, Image, Text } from 'grommet';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { ConnectorIconSmall } from '../../../../../../components/ConnectorIcon';
import { SimpleBorderTextButton } from '../../../../../../components/FilterDropdown';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../../components/prosemirror/BaseReactNode';
import { FontFamily } from '../../../../../../theme';
import { TaskOverview, TaskPreviewInfo } from './TaskPreviewNode';

function addOpacityToHex(hex: string, opacityPc: number): string {
    const opacity = opacityPc / 100;
    const _opacity = Math.round(opacity * 255);
    return hex + _opacity.toString(16).toUpperCase();
}

function InlineInsightPill(props: { data: InlineInsightPillNodeDataBase<TaskPreviewInfo> }): JSX.Element {
    const [modal, setModal] = useState(false);
    const ref = useRef<HTMLSpanElement>(null);

    return (
        <span
            ref={ref}
            onMouseEnter={() => setModal(true)}
            onMouseLeave={() => setModal(false)}
            style={{ cursor: 'default' }}
        >
            {props.data && (
                <span
                    style={{
                        backgroundColor: props.data.color ? addOpacityToHex(props.data.color, 20) : Colors.light_6,
                        padding: '4px 4px',
                        borderRadius: '6px',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: props.data.color ? props.data.color : Colors.border_light,
                        marginRight: '4px',
                        display: 'inline-flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        marginBottom: '4px',
                        fontFamily: FontFamily.Mono,
                    }}
                >
                    {props.data.iconUrl && (
                        <Image
                            src={props.data.iconUrl}
                            width={'14px'}
                            height={'14px'}
                            alt="icon"
                            style={{ alignSelf: 'flex-start', marginTop: '3px' }}
                            fallback={import.meta.env.VITE_ITEM_ICON_FALLBACK_URL}
                        />
                    )}
                    {!props.data.iconUrl && props.data.connector && (
                        <ConnectorIconSmall
                            name={props.data.connector}
                            sizePixels="14px"
                            style={{ alignSelf: 'flex-start', marginTop: '3px' }}
                        />
                    )}
                    {(props.data.iconUrl || props.data.connector) && <span style={{ marginRight: '4px' }} />}
                    {props.data.value}
                </span>
            )}
            {modal && props.data && props.data.items.length > 0 && (
                <Drop
                    target={ref}
                    onClickOutside={() => setModal(false)}
                    plain
                    align={{ top: 'bottom', left: 'left' }}
                    pad={{ top: 'xxsmall', horizontal: 'xsmall', bottom: 'xsmall' }}
                    width={{ max: '500px' }}
                    animation={{ type: 'fadeIn', duration: 100 }}
                >
                    <Box
                        overflow={{ vertical: 'auto' }}
                        pad="xxsmall"
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_light }}
                        elevation="medium"
                        round="4px"
                    >
                        <Box height={{ min: 'max-content' }} gap="xxsmall">
                            {props.data.items.map((item, i) => (
                                <TaskOverview
                                    key={`overview-${i}`}
                                    item={item}
                                    cardColor={{ color: Colors.background_back, opacity: 1 }}
                                    borderColor={Colors.border_light}
                                />
                            ))}
                        </Box>
                    </Box>
                </Drop>
            )}
        </span>
    );
}

export function InlineInsightPillOverflow(props: {
    data: InlineInsightPillNodeDataBase<TaskPreviewInfo>[];
}): JSX.Element {
    const [showMore, setShowMore] = useState(false);

    const array = useMemo(() => {
        return showMore ? props.data : props.data.slice(0, MAX_INLINE_INSIGHT_PILLS_TO_SHOW);
    }, [props.data, showMore]);

    return (
        <Box>
            <Box direction="row" gap="1px" wrap align="center">
                {array.map((d, di) => (
                    <InlineInsightPill data={d} key={`${d.value}-${di}-pill`} />
                ))}
                {props.data.length > MAX_INLINE_INSIGHT_PILLS_TO_SHOW && (
                    <SimpleBorderTextButton
                        onClick={() => setShowMore(!showMore)}
                        boxProps={{
                            border: { color: Colors.border_dark },
                            align: 'center',
                            round: '6px',
                        }}
                        render={(hover) => (
                            <Text color={hover ? Colors.accent_3 : undefined} size="14px">
                                {showMore
                                    ? `Show less`
                                    : `Show ${props.data.length - MAX_INLINE_INSIGHT_PILLS_TO_SHOW} more`}
                            </Text>
                        )}
                    />
                )}
            </Box>
        </Box>
    );
}

export class InlineInsightPillNodeReact extends BaseReactNodeInjected<InlineInsightPillAttributes<TaskPreviewInfo>> {
    _injectedNode = new InlineInsightPillNode<TaskPreviewInfo>();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<InlineInsightPillAttributes<TaskPreviewInfo>>> = (
        props,
    ) => {
        if (props.currentAttributes.data === undefined) {
            return null;
        }

        return Array.isArray(props.currentAttributes.data) ? (
            <InlineInsightPillOverflow data={props.currentAttributes.data} />
        ) : (
            <InlineInsightPill data={props.currentAttributes.data} />
        );
    };
}
