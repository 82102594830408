// Copyright 2021
// ThatWorks.xyz Limited

import {
    closestCenter,
    DndContext,
    DragEndEvent,
    DragOverlay,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Colors } from '@thatworks/colors';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, DropButton, Text } from 'grommet';
import { AddCircle, Down } from 'grommet-icons';
import { GridAreaType } from 'grommet/utils';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DropdownButton from '../../../../../components/DropdownButton';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { FontFamily } from '../../../../../theme';
import { FiltersOutput } from './FiltersOutput';
import {
    BlockType,
    QueryTemplateBlockState,
    SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID,
    TemplateBlock,
    TemplateBlockState,
} from './TemplateBlock';

export enum CreationMode {
    Automation = 'automation',
    Post = 'post',
    SavedQueries = 'saved-queries',
}

export const CreationModeTitles: { [k in CreationMode]: string } = {
    [CreationMode.Automation]: 'Create new automation',
    [CreationMode.Post]: 'Create new post',
    [CreationMode.SavedQueries]: 'Create new saved query',
};

export const CreationModeLabels: { [k in CreationMode]: string } = {
    [CreationMode.Automation]: 'Save automation',
    [CreationMode.Post]: 'Publish post',
    [CreationMode.SavedQueries]: 'Save query',
};

export const UpdateModeLabels: { [k in CreationMode]: string } = {
    [CreationMode.Automation]: 'Update schedule',
    [CreationMode.Post]: 'Publish post',
    [CreationMode.SavedQueries]: 'Update title',
};

export const CreationModeSwitch: { [k in CreationMode]: string } = {
    [CreationMode.Automation]: 'Switch to new automation',
    [CreationMode.Post]: 'Switch to new post',
    [CreationMode.SavedQueries]: 'Switch to new saved query',
};

export function MagicComposerBuilder(props: {
    gridArea: GridAreaType;
    creationMode: CreationMode;
    templateName: string;
    onUpdateTemplateName: (templateName: string) => void;
    blocks: TemplateBlockState[];
    activeBlockId: string | undefined;
    onAddBlock: (type: BlockType) => void;
    onUpdateBlock: (block: TemplateBlockState) => void;
    onDeleteBlock: (blockId: string) => void;
    updateActiveBlockId: (blockId: string, active: boolean) => void;
    onChangeBlockIndex: (activeId: string, overId: string | undefined) => void;
    onLoading: (loading: boolean) => void;
    sessionCacheId: string;
}): JSX.Element {
    const [loadingTimeline, setLoadingTimeline] = useState<boolean>(false);
    const [loadingSummary, setLoadingSummary] = useState<boolean>(false);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [dragId, setDragId] = useState<UniqueIdentifier | null>(null);

    const handleDragStart = (event: DragStartEvent) => {
        setDragId(event.active.id);
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        setDragId(null);
        if (active.id !== over?.id) {
            props.onChangeBlockIndex(active.id.toString(), over?.id.toString());
        }
    };

    const overlayItem = useMemo(() => {
        const block = props.blocks.find((b) => b.id === dragId);
        if (block) {
            return (
                <TemplateBlock
                    key={block.id}
                    onUpdateState={() => {}}
                    state={block}
                    sessionCacheId={props.sessionCacheId}
                    onDelete={() => {}}
                    active={props.activeBlockId === block.id}
                    setActiveBlock={() => {}}
                    onLoadingSummary={() => {}}
                    onLoadingTimeline={setLoadingTimeline}
                />
            );
        }
    }, [dragId, props.activeBlockId, props.blocks, props.sessionCacheId]);

    return (
        <Box
            gridArea={props.gridArea}
            height="100%"
            style={{ background: Colors.background_front }}
            pad={{ horizontal: 'xsmall', top: '10px' }}
            gap="10px"
        >
            <MagicComposerBuilderTitle creationMode={props.creationMode} />
            <Box
                height="100%"
                background={Colors.background_back}
                round={{ corner: 'top', size: '15px' }}
                pad="xsmall"
                gap="xsmall"
                direction="column"
                border={{ color: Colors.border_light, size: '1px' }}
            >
                <Box direction="row" gap="xsmall" flex>
                    {/* Blocks listing */}
                    <Box fill={'vertical'} overflow={{ vertical: 'auto' }} flex>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                        >
                            <Box height={{ min: 'max-content', height: 'max-content' }} gap="xxsmall">
                                <SortableContext items={props.blocks} strategy={verticalListSortingStrategy}>
                                    <Box gap="xxsmall">
                                        {props.blocks.map((block) => (
                                            <TemplateBlock
                                                key={block.id}
                                                onUpdateState={(updatedBlock) => {
                                                    const newState: TemplateBlockState = {
                                                        ...updatedBlock,
                                                    };
                                                    if (
                                                        newState.type === BlockType.Query &&
                                                        block.type === BlockType.Query
                                                    ) {
                                                        newState.selectedInsightIds = block.selectedInsightIds.includes(
                                                            SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID,
                                                        )
                                                            ? newState.selectedInsightIds
                                                            : block.selectedInsightIds;
                                                    }
                                                    props.onUpdateBlock(newState);
                                                }}
                                                state={block}
                                                sessionCacheId={props.sessionCacheId}
                                                onDelete={() => props.onDeleteBlock(block.id)}
                                                active={props.activeBlockId === block.id}
                                                setActiveBlock={(active) => props.updateActiveBlockId(block.id, active)}
                                                onLoadingSummary={(l) => {
                                                    setLoadingSummary(l);
                                                    props.onLoading(l);
                                                }}
                                                onLoadingTimeline={setLoadingTimeline}
                                            />
                                        ))}
                                    </Box>
                                    <DragOverlay>{dragId ? overlayItem : null}</DragOverlay>
                                </SortableContext>

                                {/* Add block button */}
                                <Box
                                    pad="xxsmall"
                                    background={{ color: Colors.background_front }}
                                    round={{ size: '15px' }}
                                    align="center"
                                    border={{ color: Colors.border_light, size: '1px' }}
                                >
                                    <IconButtonV2
                                        icon={(hover) => (
                                            <AddCircle size="16px" color={hover ? Colors.accent_3 : Colors.dark_2} />
                                        )}
                                        label={(hover) => (
                                            <Text
                                                size="14px"
                                                style={{ fontFamily: FontFamily.Standard }}
                                                color={hover ? Colors.accent_3 : Colors.dark_2}
                                            >
                                                Add new summary section
                                            </Text>
                                        )}
                                        reverse
                                        onClick={() => {
                                            props.onAddBlock(BlockType.Query);
                                        }}
                                    />
                                </Box>
                                {/* Add custom text button */}
                                <Box
                                    pad="xxsmall"
                                    background={{ color: Colors.background_front }}
                                    round={{ size: '15px' }}
                                    align="center"
                                    border={{ color: Colors.border_light, size: '1px' }}
                                >
                                    <IconButtonV2
                                        icon={(hover) => (
                                            <AddCircle size="16px" color={hover ? Colors.accent_3 : Colors.dark_2} />
                                        )}
                                        label={(hover) => (
                                            <Text
                                                size="14px"
                                                style={{ fontFamily: FontFamily.Standard }}
                                                color={hover ? Colors.accent_3 : Colors.dark_2}
                                            >
                                                Add new custom text section
                                            </Text>
                                        )}
                                        reverse
                                        onClick={() => {
                                            props.onAddBlock(BlockType.Text);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </DndContext>
                    </Box>

                    {/* Filters Output */}
                    <FiltersOutput
                        key={`index-${props.activeBlockId}`}
                        activeBlock={
                            props.blocks.find(
                                (block) => block.id === props.activeBlockId && block.type === BlockType.Query,
                            ) as QueryTemplateBlockState | undefined
                        }
                        onUpdateGroupType={(group) => {
                            const activeBlock = props.blocks.find((block) => block.id === props.activeBlockId);
                            if (activeBlock != null) {
                                const newState: TemplateBlockState = { ...activeBlock };
                                if (newState.type === BlockType.Query) {
                                    newState.groupSettings.groupType = group;
                                    // Reset subgroup ordering if group type changes
                                    // because the values/names will be different
                                    newState.groupSettings.subgroupOrdering = undefined;
                                }
                                props.onUpdateBlock(newState);
                            }
                        }}
                        onUpdateGroupOrdering={(newOrder) => {
                            const activeBlock = props.blocks.find((block) => block.id === props.activeBlockId);
                            if (activeBlock != null) {
                                const newState: TemplateBlockState = { ...activeBlock };
                                if (newState.type === BlockType.Query) {
                                    newState.groupSettings.subgroupOrdering = newOrder;
                                }
                                props.onUpdateBlock(newState);
                            }
                        }}
                        onCustomSettingsChange={(customSettings) => {
                            const activeBlock = props.blocks.find((block) => block.id === props.activeBlockId);
                            if (activeBlock != null) {
                                const newState: TemplateBlockState = {
                                    ...activeBlock,
                                };
                                if (newState.type === BlockType.Query) {
                                    newState.summarizationCustomSettings = customSettings;
                                }
                                props.onUpdateBlock(newState);
                            }
                        }}
                        onIndicatorToggled={(indicator) => {
                            const activeBlock = props.blocks.find((block) => block.id === props.activeBlockId);
                            if (activeBlock != null) {
                                const newState: TemplateBlockState = {
                                    ...activeBlock,
                                };

                                if (newState.type === BlockType.Query && activeBlock.type === BlockType.Query) {
                                    const selectedInsights = activeBlock.selectedInsightIds;
                                    const set = new Set(selectedInsights);
                                    set.delete(SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID);
                                    if (set.has(indicator)) {
                                        set.delete(indicator);
                                    } else {
                                        set.add(indicator);
                                    }
                                    newState.selectedInsightIds = Array.from(set);
                                }

                                props.onUpdateBlock(newState);
                            }
                        }}
                        timelineLoading={loadingTimeline}
                        summaryLoading={loadingSummary}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export function MagicComposerBuilderTitle(props: { creationMode: CreationMode }): JSX.Element {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const navigateTo = useCallback(
        (mode: CreationMode) => {
            let path = `${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${mode}`;
            navigate(path);
        },
        [navigate],
    );

    return (
        <Box direction="row" align="center" gap="xsmall">
            <Text size="28px" style={{ fontFamily: FontFamily.Callout }} color={Colors.dark_2} weight="bold">
                {CreationModeTitles[props.creationMode]}
            </Text>
            <DropButton
                icon={<Down size="small" color={Colors.brand} onClick={() => setOpen(!open)} />}
                style={{ padding: '0px' }}
                dropContent={
                    <Box background="transparent" direction="row">
                        <Box elevation="medium" background="brand" round="5px" pad={{ top: '10px', bottom: '10px' }}>
                            {Object.entries(CreationModeSwitch)
                                .filter(([mode, _title]) => mode !== props.creationMode)
                                .map(([mode, title], id) => (
                                    <DropdownButton
                                        key={id}
                                        label={title}
                                        onClick={async () => {
                                            navigateTo(mode as CreationMode);
                                            setOpen(false);
                                        }}
                                    />
                                ))}
                        </Box>
                    </Box>
                }
                dropAlign={{ left: 'right', top: 'bottom' }}
                dropProps={{
                    background: 'transparent',
                    elevation: 'none',
                    onEsc: () => {
                        setOpen(false);
                    },
                    onClickOutside: () => {
                        setOpen(false);
                    },
                }}
                open={open}
            />
        </Box>
    );
}
