// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation, useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { Box, Drop, Spinner, Text } from 'grommet';
import { CaretDownFill, CloudUpload, Rewind, StatusCritical, Upload } from 'grommet-icons';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    GetTemplateDraftQuery,
    SlackChannelMention,
    SlackTeamChannels,
    WorkspacePostTemplateQuery,
} from '../../../../../__generated__/graphql';
import { SimpleBorderTextButton } from '../../../../../components/FilterDropdown';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { FontFamily } from '../../../../../theme';
import {
    CREATE_TEMPLATE_SNAPSHOT,
    CREATE_UPDATE_TEMPLATE_DRAFT,
    GET_TEMPLATE_DRAFT,
    GET_TEMPLATE_SNAPSHOT_DATE,
    RESTORE_TEMPLATE_SNAPSHOT,
} from '../../templates/components/template-queries';
import {
    createSlackNotificationsFromState,
    getBlocks,
    getValidBlocks,
    TemplateUpdateReactState,
    updateTemplate,
    UpdateTemplateQl,
} from '../helpers/automation-helpers';
import { MAGIC_COMPOSER_CTA_BUTTON_HEIGHT } from './MagicComposer';
import { AutomationState } from './MagicComposerAutomate';
import { TemplateBlockState } from './TemplateBlock';

enum SaveState {
    Initialized = 'initialized',
    SaveBegin = 'save_begin',
    SaveComplete = 'save_complete',
    SaveError = 'save_error',
}

export enum AutoSaveMode {
    // Edit mode is used when editing an existing template
    Edit = 'edit',
    // Create mode is used when creating a new template
    Create = 'create',
}

function getLabel(ss: SaveState, mode: AutoSaveMode): string {
    switch (ss) {
        case SaveState.SaveBegin:
            return mode === AutoSaveMode.Create ? 'Saving draft..' : 'Saving changes..';
        case SaveState.SaveComplete:
        case SaveState.Initialized:
            return mode === AutoSaveMode.Create ? 'Draft autosaved' : 'Changes autosaved';
        case SaveState.SaveError:
            return 'Autosave failed';
    }
}

interface AutoSaveCommonProps {
    currentState: {
        templateName: string;
        blocks: TemplateBlockState[];
        automation: AutomationState;
        slackNotifications: SlackTeamChannels[];
        slackChannelMention: SlackChannelMention;
        emailNotifications: string[];
        summaryCustomFormattingPrompt: string | undefined;
    };
    updateQl: UpdateTemplateQl;
}

interface AutoSaveEditProps {
    type: AutoSaveMode.Edit;
    state: {
        initial: NonNullable<Required<WorkspacePostTemplateQuery>['workspacePostTemplate']>;
    };
}

interface AutoSaveCreateProps {
    type: AutoSaveMode.Create;
    state: {
        initial: {
            blocks: TemplateBlockState[];
            summaryCustomFormattingPrompt: string | undefined;
        };
    };
}

export type AutoSaveProps = AutoSaveEditProps | AutoSaveCreateProps;

// Ignore automation for autosave because it will be saved via the modal
// Sending an undefined value will cause the backend to ignore and not update the automation field
type TemplateUpdateReactStateWithoutAutomation = Omit<TemplateUpdateReactState, 'automation'>;

// Hook that handles the autosave logic and state
function useAutoSave(
    editCreateProps: AutoSaveProps,
    common: AutoSaveCommonProps,
    onStateUpdate: (state: SaveState) => void,
) {
    const { logger } = useTelemetryContext();
    const createSnapshot = useRef(true);
    const [createTemplateSnapshot] = useMutation(CREATE_TEMPLATE_SNAPSHOT, {
        onError: (e) => {
            logger.error(e.message);
        },
    });
    const [createUpdateDraft] = useMutation(CREATE_UPDATE_TEMPLATE_DRAFT);

    const [templateId] = useState<string | undefined>(
        editCreateProps.type === 'edit' ? editCreateProps.state.initial.id : undefined,
    );
    const [lastSavedState, setLastSavedState] = useState<TemplateUpdateReactStateWithoutAutomation | undefined>(
        editCreateProps.type === 'edit'
            ? {
                  templateId: editCreateProps.state.initial.id,
                  templateName: editCreateProps.state.initial.title,
                  validBlocks: getValidBlocks(getBlocks(editCreateProps.state.initial), {
                      allowEmptyFiltersAndSetAsUndefined: true,
                  }),
                  slackNotifications: createSlackNotificationsFromState(
                      editCreateProps.state.initial.slackNotifications || [],
                  ),
                  slackChannelMention: editCreateProps.state.initial.slackChannelMention ?? SlackChannelMention.None,
                  emailNotifications: editCreateProps.state.initial.emailNotifications,
                  summaryCustomFormattingPrompt:
                      editCreateProps.state.initial.summaryCustomFormattingPrompt || undefined,
              }
            : {
                  validBlocks: getValidBlocks(editCreateProps.state.initial.blocks),
                  summaryCustomFormattingPrompt: editCreateProps.state.initial.summaryCustomFormattingPrompt,
                  templateId: '',
                  templateName: '',
                  slackNotifications: [],
                  slackChannelMention: SlackChannelMention.None,
                  emailNotifications: [],
              },
    );

    // Run the save process for edit mode
    const processEdit = useCallback(
        async (stateToUpdate: TemplateUpdateReactStateWithoutAutomation, updateQl: UpdateTemplateQl) => {
            onStateUpdate(SaveState.SaveBegin);

            // Create a snapshot if it's the first time saving in this session
            try {
                // use a ref rather than a state to avoid re-rendering
                if (createSnapshot.current) {
                    createSnapshot.current = false;
                    await createTemplateSnapshot({
                        variables: {
                            id: stateToUpdate.templateId,
                        },
                    });
                }
            } catch (e) {
                logger.exception(e);
            }

            // Update the template
            const updateRes = await updateTemplate(
                updateQl,
                {
                    ...stateToUpdate,
                    // Sending an undefined value will cause the backend to ignore and not update the automation field
                    automation: undefined,
                },
                (error) => {
                    logger.error(error.message);
                    onStateUpdate(SaveState.SaveError);
                },
            );

            if (updateRes.data && updateRes.data.workspacePostTemplateUpdate.id) {
                // Update the last saved state and notify the parent component
                setLastSavedState(stateToUpdate);
                onStateUpdate(SaveState.SaveComplete);
            }
        },
        [createTemplateSnapshot, logger, onStateUpdate],
    );

    // Run the save process for create mode
    const processCreate = useCallback(
        async (stateToUpdate: TemplateUpdateReactStateWithoutAutomation) => {
            onStateUpdate(SaveState.SaveBegin);

            // Save as a draft which can be retrieved later
            createUpdateDraft({
                variables: {
                    template: {
                        title: stateToUpdate.templateName,
                        queries: {
                            blocks: stateToUpdate.validBlocks.queryBlocks,
                        },
                        textBlocks: stateToUpdate.validBlocks.textBlocks,
                        slackNotifications: stateToUpdate.slackNotifications,
                        slackChannelMention: stateToUpdate.slackChannelMention,
                        emailNotifications: stateToUpdate.emailNotifications,
                        summaryCustomFormattingPrompt: stateToUpdate.summaryCustomFormattingPrompt,
                        automation: undefined,
                    },
                },
                onError: (e) => {
                    logger.error(e.message);
                    onStateUpdate(SaveState.SaveError);
                },
            })
                .then(() => {
                    setLastSavedState(stateToUpdate);
                    onStateUpdate(SaveState.SaveComplete);
                })
                .catch((e) => {
                    logger.exception(e);
                    onStateUpdate(SaveState.SaveError);
                });
        },
        [createUpdateDraft, logger, onStateUpdate],
    );

    useEffect(() => {
        const debouncedSave = debounce(() => {
            const currentState: TemplateUpdateReactStateWithoutAutomation = {
                templateId: templateId ?? '',
                templateName: common.currentState.templateName,
                validBlocks: getValidBlocks(common.currentState.blocks, { allowEmptyFiltersAndSetAsUndefined: true }),
                slackNotifications: createSlackNotificationsFromState(common.currentState.slackNotifications),
                slackChannelMention: common.currentState.slackChannelMention,
                emailNotifications: common.currentState.emailNotifications,
                summaryCustomFormattingPrompt: common.currentState.summaryCustomFormattingPrompt,
            };

            // If the current state is the same as the last saved state, don't save
            if (isEqual(lastSavedState, currentState)) {
                return;
            }

            if (editCreateProps.type === 'edit') {
                processEdit(currentState, common.updateQl);
            } else {
                processCreate(currentState);
            }
        }, 500);
        debouncedSave();
        return () => {
            debouncedSave.cancel();
        };
    }, [
        lastSavedState,
        processCreate,
        processEdit,
        common.currentState.blocks,
        common.currentState.emailNotifications,
        common.currentState.slackChannelMention,
        common.currentState.slackNotifications,
        common.currentState.summaryCustomFormattingPrompt,
        common.currentState.templateName,
        editCreateProps.type,
        common.updateQl,
        templateId,
    ]);
}

// Handles the restore snapshot logic+ui
function SavedSnapshotRestore(props: { templateUuid: string; onTemplateRestored: () => void }): JSX.Element | null {
    const { logger } = useTelemetryContext();

    const { data, loading, refetch } = useQuery(GET_TEMPLATE_SNAPSHOT_DATE, {
        variables: {
            id: props.templateUuid,
        },
        onError: (e) => {
            logger.error(e.message);
        },
        // Don't cache the snapshot date to ensure it's always up to date
        fetchPolicy: 'no-cache',
    });
    const [restoreSnapshot, { loading: restoring }] = useMutation(RESTORE_TEMPLATE_SNAPSHOT, {
        onError: (e) => {
            logger.error(e.message);
        },
    });

    if (loading) {
        return <Spinner size="1px" width="14px" height="14px" />;
    }

    if (!data || !data.workspacePostTemplateSnapshotIsoDate) {
        return <Text size="12px">No previous versions available to restore</Text>;
    }

    return (
        <Box>
            <ToolbarButton
                active={false}
                icon={Rewind}
                iconSize="14px"
                label="Revert changes"
                loading={restoring}
                onClick={async () => {
                    await restoreSnapshot({
                        variables: {
                            id: props.templateUuid,
                        },
                    });
                    await refetch();
                    props.onTemplateRestored();
                }}
            />
            <Box pad={{ left: '20px' }}>
                <Text size="12px">
                    Restore the version saved {DateTime.fromISO(data.workspacePostTemplateSnapshotIsoDate).toRelative()}
                </Text>
            </Box>
        </Box>
    );
}

// Component that displays a button to load a previous draft
function LoadPreviousDraft(props: {
    draft: GetTemplateDraftQuery['workspacePostTemplateDraft'];
    loading: boolean;
    onLoadDraft: (template: NonNullable<Required<GetTemplateDraftQuery>['workspacePostTemplateDraft']>) => void;
}): JSX.Element | null {
    if (!props.loading && !props.draft) {
        return null;
    }

    return (
        <SimpleBorderTextButton
            render={(hover) => (
                <Box
                    direction="row"
                    align="center"
                    pad={{ horizontal: '14px' }}
                    height={{ height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                    border={{
                        color: hover ? Colors.accent_3 : Colors.neutral_1,
                        size: '2px',
                    }}
                    round="8px"
                    justify="center"
                    gap="xxsmall"
                >
                    {props.loading && <Spinner size="1px" width="10px" height="10px" />}
                    {!props.loading && <Upload size="14px" />}
                    {!props.loading && <Text size="14px">Load saved draft</Text>}
                </Box>
            )}
            border={{ size: '0px' }}
            onClick={() => props.onLoadDraft(props.draft!)}
        />
    );
}

// Component that displays the autosave state and allows the user to restore a snapshot
export function ComposerAutoSave(props: {
    mode: AutoSaveProps;
    common: AutoSaveCommonProps;
    onTemplateRestored: () => void;
    onLoadDraft: (template: NonNullable<Required<GetTemplateDraftQuery>['workspacePostTemplateDraft']>) => void;
}): JSX.Element | null {
    const [showDrop, setShowDrop] = useState(false);
    const [lastSaveTime, setLastSaveTime] = useState<DateTime | undefined>(undefined);
    const [saveState, setSaveState] = useState<SaveState>(SaveState.Initialized);
    const containerRef = useRef<HTMLDivElement>(null);

    const {
        data: draftData,
        loading: draftLoading,
        refetch,
    } = useQuery(GET_TEMPLATE_DRAFT, {
        skip: props.mode.type === 'edit',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const onSaveStateChange = useCallback((state: SaveState) => {
        setSaveState(state);
        if (state === SaveState.SaveComplete) {
            setLastSaveTime(DateTime.now());
        }
    }, []);

    useAutoSave(props.mode, props.common, onSaveStateChange);

    // Show the load draft button if the draft is available or loading and if no changes have been made yet
    if (
        props.mode.type === 'create' &&
        saveState === SaveState.Initialized &&
        (draftLoading || (draftData && draftData.workspacePostTemplateDraft))
    ) {
        return (
            <LoadPreviousDraft
                draft={draftData?.workspacePostTemplateDraft}
                loading={draftLoading}
                onLoadDraft={() => {
                    refetch();
                    props.onLoadDraft(draftData!.workspacePostTemplateDraft!);
                }}
            />
        );
    }

    // Don't show the autosave button in create mode until the first save has started
    if (props.mode.type === 'create' && saveState === SaveState.Initialized) {
        return null;
    }

    return (
        <Box ref={containerRef} direction="row" align="center">
            <SimpleBorderTextButton
                render={(hover) => (
                    <Box
                        background={Colors.background_back}
                        direction="row"
                        align="center"
                        pad={{ horizontal: '14px' }}
                        height={{ height: MAGIC_COMPOSER_CTA_BUTTON_HEIGHT }}
                        border={{
                            color: hover ? Colors.accent_3 : Colors.accent_4,
                            size: '2px',
                        }}
                        round="8px"
                        justify="center"
                        gap="xxsmall"
                    >
                        {saveState === SaveState.SaveBegin && <Spinner size="1px" width="10px" height="10px" />}
                        {(saveState === SaveState.SaveComplete || saveState === SaveState.Initialized) && (
                            <CloudUpload size="14px" />
                        )}
                        {saveState === SaveState.SaveError && <StatusCritical size="14px" />}
                        <Text size="14px">{getLabel(saveState, props.mode.type)}</Text>
                        {props.mode.type === 'edit' && <CaretDownFill size="14px" />}
                    </Box>
                )}
                border={{ size: '0px' }}
                onClick={() => setShowDrop(true)}
            />
            {showDrop && (
                <Drop
                    overflow="auto"
                    onClickOutside={() => setShowDrop(false)}
                    onEsc={() => setShowDrop(false)}
                    target={containerRef.current || undefined}
                    margin={{ bottom: 'xxsmall' }}
                    align={{ top: 'bottom' }}
                    round="8px"
                    background={Colors.background_front}
                    width={{ width: '250px', max: '300px' }}
                >
                    <Box
                        pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                        border={{ side: 'bottom', color: Colors.border_dark }}
                    >
                        <Text style={{ fontFamily: FontFamily.Heading }} size="14px">
                            {lastSaveTime ? `Autosaved ${lastSaveTime?.toRelative()}` : 'No changes to save'}
                        </Text>
                    </Box>
                    {props.mode.type === 'edit' && (
                        <Box pad="xsmall">
                            <SavedSnapshotRestore
                                templateUuid={props.mode.state.initial.id}
                                onTemplateRestored={props.onTemplateRestored}
                            />
                        </Box>
                    )}
                </Drop>
            )}
        </Box>
    );
}
