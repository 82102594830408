// Copyright 2021
// ThatWorks.xyz Limited

import { Box, Button, Drop, Spinner, Text } from 'grommet';
import { ColorType, PadType } from 'grommet/utils';
import React, { useEffect, useRef, useState } from 'react';
import { FontFamily } from '../theme';

export function TextDropButton(props: {
    onClick?: () => Promise<void>;
    active?: boolean;
    icon?: JSX.Element;
    tip?: string;
    spinnerColor?: ColorType;
    pad?: PadType;
    size?: string;
    fontStyle?: React.CSSProperties;
    hover?: boolean;
    render: (hover: boolean, active: boolean) => JSX.Element;
    disabled?: boolean;
}): JSX.Element {
    const textRef = useRef<HTMLSpanElement>(null);
    const [showTooltip, setShowTooltip] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const hidden = textRef && textRef.current !== null && textRef.current.offsetWidth < textRef.current.scrollWidth;
        setShowTooltip(hidden);
    }, [props.tip]);

    return (
        <Button
            disabled={props.disabled}
            onClick={async () => {
                if (props.onClick && !loading) {
                    setLoading(true);
                    await props.onClick();
                    setLoading(false);
                }
            }}
            plain
        >
            {({ hover, disabled }) => (
                <Box
                    width="inherit"
                    background={hover || props.hover || props.active ? 'dark-2' : undefined}
                    pad={props.pad || { horizontal: 'small', vertical: '8px' }}
                    direction="row"
                    gap="12px"
                    align="center"
                    round="xxsmall"
                >
                    {!loading ? props.icon : null}
                    {loading && <Spinner size="xsmall" color={props.spinnerColor} />}

                    <Text
                        ref={textRef}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontFamily: FontFamily.Standard,
                            ...props.fontStyle,
                        }}
                        size={props.size || 'medium'}
                        tip={
                            showTooltip
                                ? {
                                      plain: true,
                                      content: (
                                          <Box
                                              border={{ color: 'light-4' }}
                                              background="background-front"
                                              round="4px"
                                              width="max-content"
                                              pad="xxsmall"
                                          >
                                              <Text size="12px" color="dark-4">
                                                  {props.tip}
                                              </Text>
                                          </Box>
                                      ),
                                      dropProps: {
                                          margin: 'xsmall',
                                      },
                                  }
                                : undefined
                        }
                    >
                        {props.render(hover, props.active || false)}
                    </Text>
                </Box>
            )}
        </Button>
    );
}

export function TextDrop(props: {
    onClose: () => void;
    target?: HTMLElement;
    width?: string;
    children: React.ReactNode;
    background?: string;
    align?: {
        top?: 'top' | 'bottom';
        bottom?: 'top' | 'bottom';
        right?: 'left' | 'right';
        left?: 'left' | 'right';
    };
}): JSX.Element {
    return (
        <Drop
            round="xxsmall"
            elevation="medium"
            align={props.align || { top: 'bottom', left: 'left' }}
            margin={{ top: '5px' }}
            onClickOutside={props.onClose}
            background={props.background || 'brand'}
            target={props.target || undefined}
            stretch={false}
        >
            <Box width={props.width} fill="vertical" gap="xxsmall" pad={{ vertical: 'xxsmall' }}>
                {props.children}
            </Box>
        </Drop>
    );
}
