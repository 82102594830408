// Copyright 2021
// ThatWorks.xyz Limited

import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Logger } from './Telemetry';
import { NavNoRenderFunction } from './UseNavNoRerender';

export const AUTH_HEADER = 'Authorization';

function makeAuthHeader(token: string): Headers {
    const headers = new Headers();
    headers.append(AUTH_HEADER, `Bearer ${token}`);
    return headers;
}

export class ApiError extends Error {
    static HTTP_UNAUTHORIZED = 401;
    static HTTP_FORBIDDEN = 403;
    private _statusCode: number;

    constructor(statusCode: number, msg: string) {
        super(msg);
        Object.setPrototypeOf(this, ApiError.prototype);
        this._statusCode = statusCode;
    }

    forbiddenOrUnauth() {
        return this._statusCode === ApiError.HTTP_UNAUTHORIZED || this._statusCode === ApiError.HTTP_FORBIDDEN;
    }

    get status(): number {
        return this._statusCode;
    }
}

function wait(ms: number) {
    return new Promise<void>((resolve) => {
        setTimeout(() => resolve(), ms);
    });
}

export async function handleFetch<T>(func: () => Promise<Response>, retries = 5, retryWaitMs = 500): Promise<T> {
    const r = await func();
    if (!r.ok) {
        // unavailable or gateway timeout
        if ((r.status === 503 || r.status === 504) && retries > 0) {
            await wait(retryWaitMs);
            return handleFetch<T>(func, retries - 1, retryWaitMs);
        }
        throw new ApiError(r.status, r.statusText);
    }

    const contentType = r.headers.get('Content-Type') || r.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
        const jsonRes = await r.json();
        return jsonRes as T;
    } else {
        try {
            const text = await r.text();
            if (!text) {
                return {} as T;
            }
            return JSON.parse(text) as T;
        } catch (e) {
            return {} as T;
        }
    }
}

export function getMainWebsitePageUrl(page: string): string {
    return `${import.meta.env.VITE_WEBSITE_URL}${page}`;
}

export default class Api {
    static routes = {
        CONNECTORS: import.meta.env.VITE_API_CONNECTORS || '',
        PUBLIC: import.meta.env.VITE_API_PUBLIC || '',
        GRAPHQL: import.meta.env.VITE_API_GRAPHQL || '',
        STATSIG_PROXY: import.meta.env.VITE_API_STATSIG_PROXY || '',
    };

    private static getBaseUrl() {
        const baseUrl = import.meta.env.VITE_API_BASE;
        if (!baseUrl) {
            throw new Error(`Base API url is invalid`);
        }
        return baseUrl;
    }

    static makeUrl(routes: string[], params?: URLSearchParams, base = this.getBaseUrl()): string {
        const filtered = routes.filter((v) => v.length > 0);
        let url = [base].concat(filtered).join('/');
        if (params) {
            url = `${url}?${params.toString()}`;
        }
        return url;
    }

    static handleException(
        error: unknown,
        log: Logger,
        nav: NavNoRenderFunction,
        forbiddenUrl = joinPagesPaths([Pages.auth.root, Pages.auth.subs.login]),
    ) {
        if (error instanceof ApiError && error.forbiddenOrUnauth()) {
            nav(forbiddenUrl);
            return false;
        } else if ((error as any).name === 'AbortError') {
            // no op
            return false;
        }
        log.exception(error);
        return true;
    }

    static get<T>(
        routes: string[],
        token?: string,
        opts?: { params?: URLSearchParams; sendCookies?: boolean; signal?: AbortSignal },
    ): Promise<T> {
        const url = this.makeUrl(routes, opts?.params || undefined);
        return handleFetch(() =>
            fetch(url, {
                headers: token ? makeAuthHeader(token) : undefined,
                credentials: opts && opts.sendCookies ? 'include' : undefined,
                signal: opts?.signal || undefined,
            }),
        );
    }

    static delete<T>(
        routes: string[],
        token: string,
        opts?: { params?: URLSearchParams; sendCookies?: boolean },
    ): Promise<T> {
        const url = this.makeUrl(routes, opts?.params || undefined);
        return handleFetch(() =>
            fetch(url, {
                headers: makeAuthHeader(token),
                method: 'DELETE',
                credentials: opts && opts.sendCookies ? 'include' : undefined,
            }),
        );
    }

    static post<T, D>(
        routes: string[],
        token?: string,
        opts?: { data?: D; sendCookies?: boolean; params?: URLSearchParams },
    ): Promise<T> {
        const url = this.makeUrl(routes, opts?.params || undefined);
        const headers = token ? makeAuthHeader(token) : new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        return handleFetch(() =>
            fetch(url, {
                headers: headers,
                method: 'POST',
                body: opts && opts.data ? JSON.stringify(opts.data) : undefined,
                credentials: opts && opts.sendCookies ? 'include' : undefined,
            }),
        );
    }

    static patch<T, D>(routes: string[], token?: string, opts?: { data?: D; sendCookies?: boolean }): Promise<T> {
        const url = this.makeUrl(routes);
        const headers = token ? makeAuthHeader(token) : new Headers();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/json');
        return handleFetch(() =>
            fetch(url, {
                headers: headers,
                method: 'PATCH',
                body: opts && opts.data ? JSON.stringify(opts.data) : undefined,
                credentials: opts && opts.sendCookies ? 'include' : undefined,
            }),
        );
    }

    static getGraphQlEndpoint() {
        return this.makeUrl([Api.routes.GRAPHQL]);
    }

    static getStatsigProxyEndpoint() {
        return this.makeUrl([Api.routes.STATSIG_PROXY]);
    }
}
