// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { Events } from '@thatworks/shared-frontend/metrics';
import { Box } from 'grommet';
import { Close } from 'grommet-icons';
import { useCallback, useState } from 'react';
import { gql } from '../../../../../__generated__';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { PlainTextInput } from '../../../../../components/PlainTextInput';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';
import { TemplatePreview } from '../../templates/components/TemplatePreview';

export const UPDATE_WORKSPACE_POST = gql(/* GraphQL */ `
    mutation WorkspacePostUpdate($organizationId: ID!, $postId: ID!, $props: WorkspacePostUpdateInput!) {
        workspacePostUpdate(organizationId: $organizationId, postId: $postId, props: $props) {
            id
            title
            content
            userAbilities
            visibility
            creators {
                name
            }
            created
            updated
        }
    }
`);

export function MagicComposerEditPost(props: {
    title: string;
    content: string;
    postId: string;
    onCloseModal: () => void;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { organizationId, postErrorMessage } = useUserStateContext();
    const [content, setContent] = useState<string>(props.content);
    const [title, setTitle] = useState<string>(props.title);
    const { logEvent } = useStatsigClient();

    const [updatePost] = useMutation(UPDATE_WORKSPACE_POST, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to update post' });
            logger.error(err.message);
        },
    });

    const editPost = useCallback(async () => {
        if (organizationId && title && content) {
            const result = await updatePost({
                variables: {
                    postId: props.postId,
                    organizationId: organizationId,
                    props: {
                        title: title,
                        content,
                    },
                },
            });
            logEvent(Events.FrontendComposerPublished);

            if (result && !result.errors) {
                props.onCloseModal();
            }
        }
    }, [content, logEvent, organizationId, props, title, updatePost]);

    return (
        <Box background={Colors.light_2} round="15px" pad="xsmall" width="80vw" height="80vh" gap="xsmall">
            {/* Update and close button */}
            <Box direction="row" justify="between">
                <Box width="max-content">
                    <CtaButtonSpinnerV2 label="Update" style={{ padding: '1px 14px' }} onClick={editPost} />
                </Box>
                <IconButtonV2
                    icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                    reverse
                    onClick={props.onCloseModal}
                />
            </Box>
            {/* Preview and edit content */}
            <Box overflow={{ vertical: 'auto' }} background="white" round={'15px'} pad={{ horizontal: 'xsmall' }} flex>
                {/* Post title */}
                <Box pad={{ top: 'xsmall' }}>
                    <PlainTextInput
                        fontSize="26px"
                        value={title}
                        placeholder="Untitled"
                        autoFocus
                        style={{
                            fontFamily: FontFamily.Mono,
                            borderRadius: '4px',
                            fontWeight: 'normal',
                            letterSpacing: '-0.02em',
                            color: Colors.black,
                            background: 'unset',
                        }}
                        width="inherit"
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Box>
                <Box flex>
                    <TemplatePreview
                        initialContent={JSON.parse(props.content)}
                        blocks={[]}
                        onChange={(content) => setContent(content)}
                        editable={true}
                    />
                </Box>
            </Box>
        </Box>
    );
}
