// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { ConnectorName } from '@thatworks/connector-api';
import { DynamicConfigs } from '@thatworks/shared-frontend/metrics';
import { Box, Spinner } from 'grommet';
import { DateTime } from 'luxon';
import { useRef, useState } from 'react';
import { gql } from '../../../../__generated__';
import { GetHomeScopesQuery } from '../../../../__generated__/graphql';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { PageTitle } from '../../../../components/PageTitle';
import { getLabel, getNavIcon, SidebarNav } from '../../../../components/SidebarNav';
import { withAuth } from '../../../../components/withAuth';
import { DatePreset, TimelineDateSelection } from '../magic-composer/filters/timeline-date-selection';
import { TimeSelectionToolbarButton } from '../magic-composer/filters/TimeSelectionToolbarButton';
import { CreateAutomationDropdown } from './CreateAutomationDropdown';
import { ScopeFilterDropdown } from './ScopeFilterDropdown';
import { ScopeList, ScopesLoading } from './ScopeList';

const GET_HOME_LAST_LOAD = gql(/* GraphQL */ `
    query GetHomeLastLoad {
        homePreferences {
            lastLoadIso
        }
    }
`);

function Home(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const sessionCacheId = useRef(Math.random().toString(36).substring(7));
    const [appFilter, setAppFilter] = useState<{ label: string; id: string }[]>([]);
    const [scopes, setScopes] = useState<GetHomeScopesQuery['homeScopes']['scopes']>([]);
    const [fromDateSelection, setFromDateSelection] = useState<TimelineDateSelection | undefined>();
    const { getDynamicConfig } = useStatsigClient();

    const { loading } = useQuery(GET_HOME_LAST_LOAD, {
        onCompleted: (data) => {
            if (!data.homePreferences) {
                const config = getDynamicConfig(DynamicConfigs.HomePageDefaultDatePreset);
                const days = config.get('customDays', 0);
                setFromDateSelection({
                    customDaysStr: days ? days.toString() : '',
                    preset: days ? DatePreset.Custom : DatePreset.OneWeek,
                });
                return;
            }
            const lastLoad = DateTime.fromISO(data.homePreferences.lastLoadIso);
            if (!lastLoad.isValid) {
                return;
            }

            const daysFromNow = Math.abs(lastLoad.diffNow('days').days);
            if (daysFromNow < 1) {
                setFromDateSelection({ customDaysStr: '', preset: DatePreset.Today });
            } else if (daysFromNow > 14) {
                setFromDateSelection({ customDaysStr: '', preset: DatePreset.TwoWeeks });
            } else {
                setFromDateSelection({ customDaysStr: Math.round(daysFromNow).toString(), preset: DatePreset.Custom });
            }
        },
        fetchPolicy: 'no-cache',
    });

    return (
        <BasicPage3 activeNavButton={SidebarNav.Home} browserPageTitle={getLabel(SidebarNav.Home)} onNav={props.onNav}>
            <Box overflow={{ vertical: 'auto' }} height="100%" width="100%">
                <Box height={{ min: 'max-content' }}>
                    {/* Page title and create template button */}
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'xsmall',
                        }}
                        border={{ side: 'bottom', color: 'border' }}
                        direction="row"
                        align="center"
                        justify="between"
                        height={{ min: 'max-content' }}
                    >
                        <PageTitle icon={getNavIcon(SidebarNav.Home)}>{getLabel(SidebarNav.Home)}</PageTitle>
                    </Box>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'small',
                        }}
                        gap="xxsmall"
                    >
                        <Box height="100%" justify="between">
                            <Box fill overflow={{ vertical: 'auto' }}>
                                <Box
                                    gap="small"
                                    alignSelf="center"
                                    width={{ width: '900px', min: '840px', max: '900px' }}
                                    height={{ min: 'fit-content' }}
                                    pad={{ horizontal: 'xsmall', bottom: 'medium', top: 'xsmall' }}
                                >
                                    <Box direction="row" gap="small" align="center" justify="between">
                                        <Box direction="row" gap="small" align="center">
                                            {(!fromDateSelection || loading) && <Spinner width="18px" height="18px" />}
                                            {fromDateSelection && (
                                                <TimeSelectionToolbarButton
                                                    onDateSelection={setFromDateSelection}
                                                    selection={fromDateSelection}
                                                    loading={loading}
                                                    forceRerenderOnPropsChange
                                                />
                                            )}
                                            {scopes.length > 0 && (
                                                <ScopeFilterDropdown
                                                    scopes={scopes.map((s) => ({
                                                        name: s.scope.itemName,
                                                        id: s.scope.id,
                                                        connector: s.scope.connector as ConnectorName,
                                                    }))}
                                                    selected={appFilter}
                                                    onSelectionChange={(s) => setAppFilter([...s])}
                                                />
                                            )}
                                        </Box>
                                        {scopes.length > 0 && (
                                            <CreateAutomationDropdown scopes={scopes.map((s) => s.scope)} />
                                        )}
                                    </Box>
                                    {(loading || !fromDateSelection) && <ScopesLoading />}
                                    {!loading && fromDateSelection && (
                                        <ScopeList
                                            fromDate={fromDateSelection}
                                            sessionCacheId={sessionCacheId.current}
                                            appFilter={appFilter}
                                            onScopesLoad={setScopes}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BasicPage3>
    );
}

export default withAuth(Home);
