// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, Text } from 'grommet';
import { useMemo, useState } from 'react';
import {
    AutomationDestinationsOutput,
    AutomationScheduleEvery,
    AutomationScheduleOutput,
    GetWorkspacePostTemplatesQuery,
    SlackTeamChannels,
    WorkspacePostTemplateQueryBlockScope,
} from '../../../../../__generated__/graphql';
import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { ConnectorIconSmall } from '../../../../../components/ConnectorIcon';
import { getLabelForSchedule } from '../../../../../components/schedule-label';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { TwHeading } from '../../../../../components/TwHeading';
import { useUserStateContext } from '../../../../../components/UserContext';
import { getDaysOfWeekFromIsoDay, twentyFourHrsTo12Hrs } from '../../magic-composer/helpers/automation-types';
import { TRIGGER_TEMPLATE_AUTOMATION } from './template-queries';
import { TemplateOptions } from './TemplateOptions';

function getSlackChannels(slackTeamsChannels: SlackTeamChannels[]): string[] {
    const slackChannels: string[] = [];
    slackTeamsChannels.forEach((slackTeamChannels) => {
        slackTeamChannels.channels.forEach((channel) => slackChannels.push(channel.name));
    });
    return slackChannels;
}

function ScheduleDescription(props: {
    schedule: AutomationScheduleOutput;
    destinations: AutomationDestinationsOutput;
    slackTeamsChannels: SlackTeamChannels[];
}): JSX.Element {
    const schedule = useMemo(() => {
        return `Published every ${getLabelForSchedule(props.schedule.every)}`;
    }, [props.schedule.every]);

    const time = useMemo(() => {
        return twentyFourHrsTo12Hrs(props.schedule.hour, props.schedule.minute);
    }, [props.schedule.hour, props.schedule.minute]);

    const destinations = useMemo(() => {
        let destinations = `${props.destinations.workspaceName}`;

        const slackChannels = getSlackChannels(props.slackTeamsChannels);
        if (slackChannels.length === 0) {
            return destinations;
        } else if (slackChannels.length === 1) {
            return `${destinations} and Slack channel ${slackChannels[0]}`;
        } else {
            const remainingchannels = slackChannels.length - 1;
            return `${destinations} and Slack channels ${slackChannels[0]} and ${remainingchannels} more`;
        }
    }, [props.destinations.workspaceName, props.slackTeamsChannels]);

    const automationText = useMemo(() => {
        switch (props.schedule.every) {
            case AutomationScheduleEvery.Day:
            case AutomationScheduleEvery.WeekDay:
                return `${schedule} at ${time} to ${destinations}`;
            case AutomationScheduleEvery.Week:
                return `${schedule} on ${getDaysOfWeekFromIsoDay(
                    props.schedule.isoDayOfWeek || 1,
                )} at ${time} to ${destinations}`;
            case AutomationScheduleEvery.MonthFirstDay:
                return `Published on the first day of the month at ${time} to ${destinations}`;
            case AutomationScheduleEvery.MonthLastDay:
                return `Published on the last day of the month at ${time} to ${destinations}`;
            case AutomationScheduleEvery.MonthFirstDayOfWeek:
                return `Published every first ${getDaysOfWeekFromIsoDay(
                    props.schedule.isoDayOfWeek || 1,
                )} of the month at ${time} to ${destinations}`;
            case AutomationScheduleEvery.MonthLastDayOfWeek:
                return `Published every last ${getDaysOfWeekFromIsoDay(
                    props.schedule.isoDayOfWeek || 1,
                )} of the month at ${time} to ${destinations}`;
            case AutomationScheduleEvery.MonthDay:
                return `Published on the ${
                    props.schedule.dayOfMonth === 31 ? `31 (or end of the month)` : props.schedule.dayOfMonth
                } of every month at ${time} to ${destinations}`;
        }
    }, [destinations, props.schedule.dayOfMonth, props.schedule.every, props.schedule.isoDayOfWeek, schedule, time]);

    return (
        <Box gap={'xxsmall'}>
            <Text size="16px" weight="bold">
                Automation
            </Text>
            <Text size="14px">{automationText}</Text>
        </Box>
    );
}

export function getDescriptionFromTemplateBlocks(
    template: GetWorkspacePostTemplatesQuery['workspacePostTemplates'][0],
) {
    const res: {
        title: string | undefined;
        scopes: WorkspacePostTemplateQueryBlockScope[];
        displayIndex: number;
    }[] = [];

    template.queryBlocks.forEach((b) => {
        res.push({
            title: b.filterName || undefined,
            scopes: b.scopes,
            displayIndex: b.displayIndex,
        });
    });

    template.textBlocks.forEach((b) => {
        res.push({
            title: 'Custom Text',
            displayIndex: b.displayIndex,
            scopes: [],
        });
    });

    res.sort((a, b) => a.displayIndex - b.displayIndex);
    return res;
}

export function TemplateSummary(props: {
    template: GetWorkspacePostTemplatesQuery['workspacePostTemplates'][0];
    onDeleteTemplate: () => void;
    onUpdateTemplate: () => void;
}): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const [showTestModal, setShowTestModal] = useState(false);
    const [triggerTemplateAutomation] = useMutation(TRIGGER_TEMPLATE_AUTOMATION, {
        onError: (error) => {
            postErrorMessage({
                title: `Error`,
                shortDesc: `Failed to trigger template automation`,
            });
            logger.error(error.message);
        },
    });
    const slackTeamsChannels = useMemo(() => {
        const res: SlackTeamChannels[] = [];
        props.template.slackNotifications.forEach((n) => {
            res.push({
                teamId: n.teamId,
                teamName: n.teamName,
                channels: n.channels.filter((c) => n.channels.map((c) => c.id).includes(c.id)),
            });
        });
        return res;
    }, [props.template.slackNotifications]);

    const orderedBlocks = useMemo(() => getDescriptionFromTemplateBlocks(props.template), [props.template]);

    return (
        <Box
            pad="xsmall"
            border={{ color: Colors.border_light, size: '2px' }}
            round="8px"
            width="large"
            gap="xsmall"
            background={{ color: Colors.background_back }}
        >
            {/* Header */}
            <Box gap="xxsmall">
                <Box direction="row" align="center" justify="between">
                    {/* Template title */}
                    <TwHeading level={4}>{props.template.title}</TwHeading>

                    {/* Template dropdown */}
                    <TemplateOptions
                        template={props.template}
                        onDeleteTemplate={props.onDeleteTemplate}
                        onUpdateTemplate={props.onUpdateTemplate}
                        onTriggerAutomation={() => setShowTestModal(true)}
                    />
                </Box>
                {/* Automation description */}
                {props.template.automation?.enabled && (
                    <ScheduleDescription
                        schedule={props.template.automation.schedule}
                        destinations={props.template.automation.destinations}
                        slackTeamsChannels={slackTeamsChannels}
                    />
                )}
            </Box>
            {/* Body */}
            <Box gap="xxsmall" wrap>
                <Text size="16px" weight="bold">
                    Sections
                </Text>
                <Box
                    background={Colors.background_front}
                    round="5px"
                    pad="xxsmall"
                    gap="xxsmall"
                    border={{ size: '1px', color: Colors.border_light }}
                >
                    {orderedBlocks.map((b, bi) => (
                        <Box key={`${props.template.id}-${bi}`} id={`id-${bi}`} round="8px" gap={'xxsmall'} wrap>
                            {b.title && (
                                <Text size="14px" weight="bold">
                                    {b.title}
                                </Text>
                            )}
                            <Box gap={'xxsmall'}>
                                {b.scopes.map((s, si) => (
                                    <Box key={`${props.template.id}-${bi}-${si}`} direction="row" align="center">
                                        <ConnectorIconSmall sizePixels="14px" name={s.connector as ConnectorName} />
                                        <Text size="14px" truncate>
                                            &nbsp;{s.itemName}
                                        </Text>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            {props.template.automation && (
                <ConfirmationModal
                    showModal={showTestModal}
                    message={`This will publish a post to topic "${props.template.automation?.destinations?.workspaceName}" and send out any notifications that are configured.`}
                    title="Trigger this automation?"
                    onCancel={() => setShowTestModal(false)}
                    onClose={() => setShowTestModal(false)}
                    onConfirm={async () => {
                        const r = await triggerTemplateAutomation({
                            variables: { templateId: props.template.id },
                        });
                        if (r && r.data && r.data.workspacePostTemplateTriggerAutomation) {
                            postErrorMessage(
                                {
                                    title: `Success`,
                                    shortDesc: `New post published to "${props.template.automation?.destinations?.workspaceName}"`,
                                },
                                'info',
                            );
                        }
                    }}
                    cta={{ text: 'Trigger', color: Colors.brand }}
                />
            )}
        </Box>
    );
}
