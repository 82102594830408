// Copyright 2021
// ThatWorks.xyz Limited

export const Pages = {
    auth: {
        root: '/auth',
        subs: {
            login: 'login',
            callback: 'callback',
            loginNoMagic: 'login-no-magic',
        },
    },
    app: {
        root: '/0',
        subs: {
            connect: { root: 'connect', subs: { status: 'status' } },
            data: 'data',
            automations: 'automations',
            internalSettings: 'internal-settings',
            recaps: 'recaps',
            welcome: 'welcome',
            /**
             * @deprecated Use topics instead.
             */
            workspaces: 'ws',
            topics: { root: 'topics', subs: { settings: 'settings' } },
            create: 'create',
            savedQueries: 'saved-queries',
            home: 'home',
        },
    },
    connector_oauth: {
        root: '/connector-oauth',
        subs: {
            oauthComplete: 'oauth-complete',
        },
    },
    tester: '/tester',
    public: {
        root: '/p',
        subs: {
            post: 'post',
            token: 't',
        },
    },
};

export const WebsitePages = {
    privacy: '/privacy',
    terms: '/terms',
    cookiePolicy: '/cookie-policy',
};

export const NEW_COLLECTION_URL_ID = 'new';

export enum QueryParams {
    Error = 'error',
    ConnectorName = 'connector',
    OAuthConnectionData = 'data',
    PostExpansion = 'expand',
    ShowComposer = 'show_composer',
    Edit = 'edit',
    Mode = 'mode',
    Template = 'template',
    Scopes = 'scopes',
}

export enum QueryParamErrors {
    GenericOauthFailure = 'OauthFailure',
    OAuthPermissionIncorrect = 'OAuthPermissionIncorrect',
}

export function getQueryParamErrorDescription(err: string | QueryParamErrors): string {
    if (!Object.values<string>(QueryParamErrors).includes(err)) {
        return 'We ran into an error while trying to connect';
    }
    switch (err as QueryParamErrors) {
        case QueryParamErrors.GenericOauthFailure:
            return 'We ran into an error while trying to connect';
        case QueryParamErrors.OAuthPermissionIncorrect:
            return 'Incorrect permissions granted, please reconnect with all permission options ticked.';
    }
}

export function joinPagesPaths(paths: string[]) {
    return paths.join('/');
}
