// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { useStatsigClient } from '@statsig/react-bindings';
import { Colors } from '@thatworks/colors';
import { Events } from '@thatworks/shared-frontend/metrics';
import { Box, InfiniteScroll, Text } from 'grommet';
import { useMemo } from 'react';
import { gql } from '../../../../__generated__';
import { GetHomeScopesQuery } from '../../../../__generated__/graphql';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import {
    TimelineDateSelection,
    timelineDateSelectionToTimelineCreateDate,
} from '../magic-composer/filters/timeline-date-selection';
import { HomeScopeSummarySkeleton, ScopeSummary } from './ScopeSummary';

const GET_HOME_SCOPES = gql(/* GraphQL */ `
    query GetHomeScopes($fromDate: TimelineCreateDate!, $cacheSessionKey: String!) {
        homeScopes(fromDate: $fromDate, cacheSessionKey: $cacheSessionKey) {
            scopes {
                scope {
                    id
                    connector
                    itemName
                    itemType
                    itemUuid
                    hierarchyType
                    parentName
                }
                favorite
                numItems
                timelineId
                activity {
                    title
                    groups {
                        type
                        subgroups {
                            ids
                            name
                            props {
                                ... on ItemSubgroupTaskStatus {
                                    isDoneStatus
                                    sortOrder
                                    status
                                }
                            }
                            summary {
                                summary {
                                    sections {
                                        markdown
                                        pills {
                                            value
                                            color
                                            iconUrl
                                            itemUuids
                                            connector
                                        }
                                        newRowForPills
                                    }
                                }
                            }
                        }
                    }
                    items {
                        title
                        id
                        iconUrl
                        connector
                        url
                        actors {
                            names {
                                color
                                isUser
                                name
                            }
                            hasMore
                        }
                        parents {
                            readableConnectorObjectType
                            name
                            url
                        }
                        properties {
                            color
                            name
                            value
                            iconUrl
                            valueType
                            propertyType
                        }
                        changeDescription {
                            color
                            decoration
                            value
                        }
                        timeRange {
                            newest
                            oldest
                        }
                        docDiff {
                            ... on DocDiffSummarized {
                                summary
                            }
                        }
                        comments {
                            comments {
                                authorDisplayName
                                comment
                                date
                                userMention
                            }
                            more
                            total
                        }
                    }
                }
            }
            minNumUpdates
            maxNumUpdates
        }
    }
`);

export function ScopesLoading(): JSX.Element {
    return (
        <Box
            border={{ color: Colors.border_light }}
            round="10px"
            background={{ color: Colors.background_back }}
            elevation={'xsmall'}
            animation={{ type: 'fadeIn', duration: 400 }}
            pad={'small'}
        >
            <HomeScopeSummarySkeleton />
        </Box>
    );
}

export function ScopeList(props: {
    fromDate: TimelineDateSelection;
    sessionCacheId: string;
    appFilter: { label: string; id: string }[];
    onScopesLoad: (scopes: GetHomeScopesQuery['homeScopes']['scopes']) => void;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const { logEvent } = useStatsigClient();

    const { data, loading } = useQuery(GET_HOME_SCOPES, {
        variables: {
            fromDate: timelineDateSelectionToTimelineCreateDate(props.fromDate),
            cacheSessionKey: props.sessionCacheId,
        },
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to get data' });
            logger.error(err.message);
        },
        onCompleted: (data) => {
            props.onScopesLoad(data.homeScopes.scopes);
        },
    });

    const scopes = useMemo(() => {
        if (!data) {
            return [];
        }

        if (props.appFilter.length === 0) {
            return data.homeScopes.scopes;
        }

        const ids = new Set(props.appFilter.map((c) => c.id));
        return data.homeScopes.scopes.filter((scope) => ids.has(scope.scope.id));
    }, [props.appFilter, data]);

    if (loading) {
        return <ScopesLoading />;
    }

    if (!data || scopes.length === 0) {
        return (
            <Box
                border={{ color: Colors.border_dark }}
                round="10px"
                background={{ color: Colors.background_back }}
                elevation={'xsmall'}
                animation={{ type: 'fadeIn', duration: 400 }}
                pad="small"
            >
                <Text size="16px">No updates. Select a different time period.</Text>
            </Box>
        );
    }

    return (
        <Box overflow={{ vertical: 'auto' }}>
            <InfiniteScroll items={scopes} step={2} onMore={() => logEvent(Events.FrontendHomePageLoadMore)}>
                {(
                    item: GetHomeScopesQuery['homeScopes']['scopes'][0],
                    index: number,
                    ref: React.LegacyRef<HTMLDivElement>,
                ) => {
                    return (
                        <Box
                            margin={{ bottom: 'small' }}
                            height={{ min: 'fit-content' }}
                            ref={ref}
                            key={`scope-${item.scope.id}-${index}`}
                        >
                            <ScopeSummary
                                scope={item}
                                defaultOpen
                                key={`scope-summary-${item.scope.id}`}
                                sessionCacheId={props.sessionCacheId}
                                fromDate={props.fromDate}
                                maxNumUpdates={data.homeScopes.maxNumUpdates}
                                minNumUpdates={data.homeScopes.minNumUpdates}
                                favorite={item.favorite}
                            />
                        </Box>
                    );
                }}
            </InfiniteScroll>
        </Box>
    );
}
